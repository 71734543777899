<template>
  <div
    id=""
    v-if="bankAccounts.length > 0"
    class="container-show-bank-account">
    <div class="">
      <div
        v-for="(bankAccount, idx) in bankAccounts"
        :key="idx"
        class="container-bank-account-user is-flex is-justify-content-space-between line-bottom-gray pb-4"
      >
        <div
          class="is-flex is-align-items-center"
          style="gap: 15px;">
          <div class="is-flex">
            <img src="https://emituscdn.com/bank-icon.svg" alt="">
          </div>
          <div>
            <p
              class="text-basier-medium white title-bank-account">
              BANK ACCOUNT
            </p>
            <span
              class="is-flex is-align-items-center gray-ultra-light">
              {{ bankAccount.IBAN_A }}*****{{bankAccount.IBAN_B}} {{ ' (' + bankAccount.BankFormat + ')'}}
            </span>
          </div>
        </div>
        <div>
          <a
            class="text-basier link-text-purple delete-account-link"
            style="padding-left: 0px;"
            @click="deleteBankAccount(bankAccount.BankAccountId)"
          >
            Delete account
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
export default {
  data () {
    return {
      bankAccounts: []
    }
  },
  async created () {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    MpService.getMyBankAccounts(idToken)
      .then(BankAccountsArr => {
        this.bankAccounts = BankAccountsArr.data
      })
  },
  methods: {
    // deleteBankAccount
    async deleteBankAccount (BankAccountId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      try {
        const response = await MpService.deleteBankAccount(idToken, BankAccountId)
        if (response.data.Active === false) {
          this.notification('Cuenta bancaria eliminada')
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
      }
    },
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    }
  }
}
</script>

<style scoped>

.delete-account-link {
  font-size: 12.5px;
}

.title-bank-account {
  margin-bottom: 7px;
  font-size: 13px;
}

.container-bank-account-user {
  margin-top: 40px;
}

.container-show-bank-account {
  max-width: 410px;
}
.container-show-bank-account h1 {
  font-size: 26px!important;
  margin: 60px 0 15px 0;
}

.line-bottom-gray {
  border-bottom: 1px solid rgba(238, 238, 238, 0.4);
}
</style>
