<template>
  <div id="edit_avatar" class="container-edit-photo">
    <br>
    <h1 class="title-regular mb-5 ml-3">Sube una <br> foto nueva</h1>
    <section v-if="files.length < 2">
    <!-- <section v-if="files.length < 2 && status !== 'Verified'"> -->
      <div class="mt-10">
        <div class="is-flex is-flex-direction-column">
          <!-- Preview Image -->
          <img class="img-avatar-preview" v-if="image" :src="image" alt="card_image">
          <!-- Drop image -->
          <b-field
            v-if="showFileDrop"
            class="mt-5">
            <b-upload
              v-model="dropFiles"
              multiple
              @change.native="handleImage"
              drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <img
                    class="mb-5 mt-5"
                    width="25px"
                    src="https://emituscdn.com/Upload-icon.svg">
                  <p
                    class="paragraph-content gray-emitus">
                    Arrastra tu foto aquí o haz click para subirla
                    <br>
                    (.jpg .jpeg. or .png)</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <!-- C2A Cancell-->
          <div class="is-flex mt-5 is-justify-content-space-between">
            <b-button
              v-if="image"
              @click="fileCancel"
              class="button-secundary-small text-basier"
              >
              <span class="text-basier-medium">Cancel</span>
            </b-button>
            <!-- C2A OK-->
            <b-button
              v-if="image"
              @click="uploadImage"
              class="button-primary-medium text-basier"
              >
              <span class="text-basier">Guardar</span>
            </b-button>
            <div v-if="loader">
              <Loading/>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import Media from '@/services/MediaService'
import UserService from '@/services/UserService'

export default {
  components: {
    Loading
  },
  name: 'EditAvatar',

  data () {
    return {
      user: null,
      files: [],
      dropFiles: [],
      image: '',
      status: null,
      refusedReason: null,
      loader: false
    }
  },

  // CREATED

  async created () {
    // Listen to data
    this.notify.$on('updateUser', (user) => {
      this.user = user
      this.status = user.verified
      this.refusedReason = user.refusedReason
    })

    // await this.notify.$emit('updateData')
  },

  // COMPUTED

  computed: {
    showFileDrop: function () {
      if (this.dropFiles.length > 0) {
        return false
      } else {
        return true
      }
    }

  },

  // WATCH

  watch: {
    dropFiles: function () {
      if (this.dropFiles.length > 0) {
        this.handleImage()
      }
    }
  },

  // METHODS
  methods: {
    // Handle Image
    async handleImage () {
      const selectedImage = this.dropFiles[0]
      // const selectedImage = e.target.files[0] // get first file
      this.createBase64Image(selectedImage)
    },
    async createBase64Image (fileObject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },

    // file cancell
    async fileCancel () {
      this.dropFiles = []
      this.image = ''
    },

    // Upload image
    async uploadImage () {
      this.loader = true
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      // Upload image to Cloudinary
      const response = await Media.uploadImageCloudinary(this.image)
        .catch(err => {
          console.log('----> Error uploadImageCloudinary', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> Uploaded image', response)

      const imgData = {
        public_id: response.data.public_id,
        width: response.data.width,
        height: response.data.height,
        version: response.data.version
      }

      // Upload image to S3 Full
      const pictureS3Full = await Media.uploadImageS3(idToken, imgData)
        .catch(err => {
          console.log('----> Error uploadImageS3', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> pictureS3Full', pictureS3Full)

      // Upload image to S3 CDN
      const pictureS3CDN = await Media.uploadImageS3CDN(idToken, imgData)
        .catch(err => {
          console.log('----> Error uploadImageS3CDN', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> uploadImageS3CDN', pictureS3CDN)

      // Delete old images from S3
      if (this.user && !this.user.avatarS3Key) {
      } else {
        const deletedResponse = await Media.deleteImageS3(idToken)
          .catch(err => {
            console.log('----> Error deleteImageS3', err)
            this.danger('Algo salió mal')
            this.loader = false
          })
      // console.log('----> deleteImageS3', deletedResponse)
      }

      // Save url to DB: user.avatar
      const url = 'https://' + pictureS3CDN.data.rta.Location.slice(25)
      const user = await UserService.updateUser(idToken,
        {
          avatar: url,
          avatarCloudinaryId: imgData.public_id,
          avatarS3Key: pictureS3CDN.data.rta.Key
        })
        .then(response => {
          this.notify.$emit('refreshAvatar', response.data.avatar)
          this.notification('Foto de perfil guardada')
          this.notify.$emit('hiddeModalAvatarEdit')
        })
        .catch(err => {
          console.log('----> Error updateUser', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> avatar', user)

      // Delete image from Cloudinary
      const responseDelete = await Media.deleteImageCloudinary(idToken, imgData.public_id)
        .catch(err => {
          console.log('----> Error deleteImageCloudinary', err.response.data.msg)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> Delete image from Cloudinary', responseDelete)

      if (responseDelete.data.msg !== 'Image successfully deleted') {
        this.danger(responseDelete.data.msg)
        this.loader = false
      }

      // Update avatar in App
      this.notify.$emit('updateData')
      this.fileCancel()
    },

    // Delete profile picture
    async deleteProfilePic () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      // Delete old images from S3
      const deletedResponse = await Media.deleteImageS3(idToken)
        .catch(err => {
          console.log('----> Error deleteImageS3', err)
          this.danger('Algo salió mal')
        })

      // Save url to DB: user.avatar
      const user = await UserService.updateUser(idToken,
        {
          avatar: 'https://emituscdn.com/avatar.svg',
          avatarCloudinaryId: '',
          avatarS3Key: ''
        })
        .then(() => {
          this.notification('Perfil eliminado')
        })
        .catch(err => {
          console.log('----> Error updateUser', err)
          this.danger('Algo salió mal')
        })
      // console.log('----> avatar', user)

      // Update avatar in App
      this.notify.$emit('updateData')
      this.fileCancel()
    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    }

  }

}
</script>

<style>

#edit_avatar {
  width: 90%;
  max-width: 272px;
  margin: 9px auto;
}

#edit_avatar .upload .upload-draggable {
  border: 1px solid #b5b5b5;
  border-radius: 2px;
  padding: 15px;
}
#edit_avatar .upload .upload-draggable .section {
  position:relative
}
#edit_avatar .upload .upload-draggable .section:before, #edit_avatar .upload .upload-draggable .section:after, #edit_avatar .upload .upload-draggable .section>:first-child:before, #edit_avatar .upload .upload-draggable .section>:first-child:after {
  position:absolute; content:' ';
  width:30px; height: 30px;
  border-color:#6E3DEA; /* or whatever colour */
  border-style:solid; /* or whatever style */
}
#edit_avatar .upload .upload-draggable .section:before {
  top:0;left:0;border-width: 3px 0 0 3px
}
#edit_avatar .upload .upload-draggable .section:after {
  top:0;right:0;border-width: 3px 3px 0 0
}
#edit_avatar .upload .upload-draggable .section>:first-child:before {
  bottom:0;right:0;border-width: 0 3px 3px 0
}
#edit_avatar .upload .upload-draggable .section>:first-child:after {
  bottom:0;left:0;border-width: 0 0 3px 3px
}
.img-avatar-preview {
  object-fit: cover;
  width: 100%;
  height: 280px;
}
@media (min-width: 768px) {
  .img-avatar-preview {
    object-fit: cover;
    width: 100%;
    height: 280px;
  }
}
</style>
