<template>
  <div>
    <div id="navSide_profile" class="navSide is-hidden-touch">
      <b-collapse
        animation="slide"
        v-for="(collapse, index) of collapses"
        :key="index"
        :open="isOpen == index"
        @open="isOpen = index">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            :id="collapse.section + '_id'"
        >
            <a class="card-header-icon">
              <div
                :class="props.open ? 'barra-lateral' : ''">
              </div>
            </a>
            <p class="card-header-title text-basier" @click="handlerSections(collapse.section)">
              {{ collapse.title }}
            </p>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <ul>
              <li
                v-for="(item, index) of collapse.items"
                :key="index"
              >
                <a :id="item.navside" @click="handler(item.click)"  class="Basier-mini-regular">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="navSide-mobile is-hidden-desktop">
      <b-collapse
        animation="slide"
        v-for="(collapseMobile, index) of collapsesMobile"
        :key="index + '_M'"
        :open="isOpen == index"
        @open="isOpen = index">
        <template #trigger="props">
          <div
            :class="props.open ? 'select_section' : ''"
            class="card-header"
            role="button"
            :id="collapseMobile.section + '_idM'"
        >
            <!-- <a class="card-header-icon">
              <div
                :class="props.open ? 'barra-lateral' : ''">
              </div>
            </a> -->
            <p class="card-header-title text-basier" @click="handlerSections(collapseMobile.section)">
              {{ collapseMobile.title }}
            </p>
          </div>
        </template>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavSideMe',
  data () {
    return {
      isOpen: 0,
      collapses: [
        {
          title: 'Mi Perfil',
          section: 'profile',
          items: [
            {
              name: 'Contacto',
              click: 'click1',
              navside: 'navSide1'
            },
            {
              name: 'Presentación',
              click: 'click2',
              navside: 'navSide2'
            },
            {
              name: 'Idiomas',
              click: 'click3',
              navside: 'navSide3'
            },
            {
              name: 'Especialidades',
              click: 'click4',
              navside: 'navSide4'
            },
            {
              name: 'Experiencia',
              click: 'click5',
              navside: 'navSide5'
            },
            {
              name: 'Educación',
              click: 'click6',
              navside: 'navSide6'
            },
            {
              name: 'Redes Sociales',
              click: 'click7',
              navside: 'navSide7'
            }
          ]
        },
        {
          title: 'Mis Servicios',
          section: 'sessions',
          items: [
            {
              name: 'Tarifas',
              click: 'click1b',
              navside: 'navSide1b'
            },
            // {
            //   name: 'Time zone',
            //   click: 'click2b',
            //   navside: 'navSide2b'
            // },
            {
              name: 'Disponibilidad',
              click: 'click3b',
              navside: 'navSide3b'
            },
            {
              name: 'Días especiales',
              click: 'click4b',
              navside: 'navSide4b'
            }
          ]
        },
        {
          title: 'Mi Banco',
          section: 'bank',
          items: [
            {
              name: 'Cuenta bancaria',
              click: 'click1c',
              navside: 'navSide1c'
            },
            {
              name: 'Tarjetas bancarias',
              click: 'click2c',
              navside: 'navSide2c'
            }
          ]
        }
      ],
      collapsesMobile: [
        {
          title: 'Perfil',
          section: 'profile'
        },
        {
          title: 'Sesiones',
          section: 'sessions'
        },
        {
          title: 'Billetera',
          section: 'bank'
        }
      ]
    }
  },
  components: {},
  mounted () {
    window.addEventListener('scroll', this.scrollSideNav)
    const resp = window.scrollY
    if (resp === 0) {
      document.getElementById('navSide1').classList.add('seleccionado')
    } else {
      document.getElementById('navSide1').classList.remove('seleccionado')
    }
    window.addEventListener('scroll', this.scrollSideNavvvv)
    const respScroll = window.scrollY
    if (respScroll === 0) {
      document.getElementById('navSide1b').classList.add('seleccionado')
    } else {
      document.getElementById('navSide1b').classList.remove('seleccionado')
    }
    window.addEventListener('scroll', this.scrollSideNavC)
    const respC = window.scrollY
    if (respC === 0) {
      document.getElementById('navSide1c').classList.add('seleccionado')
    } else {
      document.getElementById('navSide1c').classList.remove('seleccionado')
    }
  },
  methods: {
    // -----------------------
    //        CHANGE SECTION
    // ----------------------
    changeSection (section) {
      this.notify.$emit('changeSection', section)
    },
    handlerSections (section) {
      this.changeSection(section)
      window.scroll(0, 0)
    },

    // -----------------------
    //        SCROLL
    // ----------------------

    // A) My Profile
    scrollSideNav () {
      const resp = window.scrollY
      // console.log(resp)
      if (resp >= 0 && resp < 899) {
        document.getElementById('navSide1').classList.add('seleccionado')
      } else {
        document.getElementById('navSide1').classList.remove('seleccionado')
      }
      if (resp > 899 && resp < 1316) {
        document.getElementById('navSide2').classList.add('seleccionado')
      } else {
        document.getElementById('navSide2').classList.remove('seleccionado')
      }
      if (resp > 1316 && resp < 1765) {
        document.getElementById('navSide3').classList.add('seleccionado')
      } else {
        document.getElementById('navSide3').classList.remove('seleccionado')
      }
      if (resp > 1765 && resp < 2381) {
        document.getElementById('navSide4').classList.add('seleccionado')
      } else {
        document.getElementById('navSide4').classList.remove('seleccionado')
      }
      if (resp > 2381 && resp < 2797) {
        document.getElementById('navSide5').classList.add('seleccionado')
      } else {
        document.getElementById('navSide5').classList.remove('seleccionado')
      }
      if (resp > 2797 && resp < 3493) {
        document.getElementById('navSide6').classList.add('seleccionado')
      } else {
        document.getElementById('navSide6').classList.remove('seleccionado')
      }
      if (resp > 3493) {
        document.getElementById('navSide7').classList.add('seleccionado')
      } else {
        document.getElementById('navSide7').classList.remove('seleccionado')
      }
    },
    click1 () {
      const scrollDiv1 = document.getElementById('navSideProfile1').offsetTop
      window.scrollTo({ top: scrollDiv1 - 170, behavior: 'smooth' })
    },
    click2 () {
      const scrollDiv2 = document.getElementById('navSideProfile2').offsetTop
      window.scrollTo({ top: scrollDiv2 - 170, behavior: 'smooth' })
    },
    click3 () {
      const scrollDiv3 = document.getElementById('navSideProfile3').offsetTop
      window.scrollTo({ top: scrollDiv3 - 170, behavior: 'smooth' })
    },
    click4 () {
      const scrollDiv4 = document.getElementById('navSideProfile4').offsetTop
      window.scrollTo({ top: scrollDiv4 - 170, behavior: 'smooth' })
    },
    click5 () {
      const scrollDiv5 = document.getElementById('navSideProfile5').offsetTop
      window.scrollTo({ top: scrollDiv5 - 170, behavior: 'smooth' })
    },
    click6 () {
      const scrollDiv6 = document.getElementById('navSideProfile6').offsetTop
      window.scrollTo({ top: scrollDiv6 - 170, behavior: 'smooth' })
    },
    click7 () {
      const scrollDiv7 = document.getElementById('navSideProfile7').offsetTop
      window.scrollTo({ top: scrollDiv7 - 170, behavior: 'smooth' })
    },

    // B) Sessions
    scrollSideNavvvv () {
      // console.log('asdassssss')
      const respScroll = window.scrollY
      // console.log(respScroll)
      if (respScroll >= 0 && respScroll < 790) {
        document.getElementById('navSide1b').classList.add('seleccionado')
      } else {
        document.getElementById('navSide1b').classList.remove('seleccionado')
      }
      // if (respScroll > 850 && respScroll < 1146) {
      //   document.getElementById('navSide2b').classList.add('seleccionado')
      // } else {
      //   document.getElementById('navSide2b').classList.remove('seleccionado')
      // }
      if (respScroll >= 790 && respScroll < 2125) {
        document.getElementById('navSide3b').classList.add('seleccionado')
      } else {
        document.getElementById('navSide3b').classList.remove('seleccionado')
      }
      if (respScroll > 2135) {
        document.getElementById('navSide4b').classList.add('seleccionado')
      } else {
        document.getElementById('navSide4b').classList.remove('seleccionado')
      }
    },
    // Navegation
    clickCheckbox (e) {
      console.log(e)
    },
    click1b () {
      const scrollDiv1b = document.getElementById('navSideProfile1b').offsetTop
      window.scrollTo({ top: scrollDiv1b - 170, behavior: 'smooth' })
    },
    // click2b () {
    //   window.scroll(0, 950)
    // },
    click3b () {
      const scrollDiv2b = document.getElementById('navSideProfile2b').offsetTop
      window.scrollTo({ top: scrollDiv2b - 170, behavior: 'smooth' })
    },
    click4b () {
      const scrollDiv3b = document.getElementById('navSideProfile3b').offsetTop
      window.scrollTo({ top: scrollDiv3b - 170, behavior: 'smooth' })
    },

    // C) Bank
    scrollSideNavC () {
      const respC = window.scrollY

      if (respC >= 0 && respC < 409) {
        document.getElementById('navSide1c').classList.add('seleccionado')
      } else {
        document.getElementById('navSide1c').classList.remove('seleccionado')
      }
      if (respC >= 409 && respC < 1146) {
        document.getElementById('navSide2c').classList.add('seleccionado')
      } else {
        document.getElementById('navSide2c').classList.remove('seleccionado')
      }
    },
    click1c () {
      const scrollDiv1c = document.getElementById('navSideProfile1c').offsetTop
      window.scrollTo({ top: scrollDiv1c - 170, behavior: 'smooth' })
    },
    click2c () {
      const scrollDiv2c = document.getElementById('navSideProfile2c').offsetTop
      window.scrollTo({ top: scrollDiv2c - 170, behavior: 'smooth' })
    },

    // Handler
    handler (click) {
      this[click]()
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollSideNav)
    window.removeEventListener('scroll', this.scrollSideNavvvv)
    window.removeEventListener('scroll', this.scrollSideNavC)
  }
}
</script>

<style scoped>
.barra-lateral {
  height: 100%;
  width: 5px;
  background-color: #25C16F;
}
.select_section {
  border-bottom: 2.8px solid #00D6CB;
  border-radius: 0px;
}
.card-header-icon {
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.card-header-title {
  color: #fff;
  padding-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}
.card-content {
  padding: 0px 10px 15px 20px;
}
.content ul {
  margin: 0;
}

.card-header {
  box-shadow: none !important;
}
.seleccionado {
  color: #25C16F !important;
}
.title-navSide {
  font-size: 22px;
  line-height: 30px;
}
.navSide-mobile {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 6;
  display: flex;
  background: #000;
  justify-content: space-evenly;
  padding-right: 86px;
  height: 55px;
}
.navSide {
    position: fixed;
    top: 174px;
    left: calc(55% - 590px);
    text-align: end;
}
.navSide a {
    color: rgba(232, 232, 232, 0.7);
    cursor: pointer;
    display: block;
    transition: 0.3s;
    text-decoration: none;
}
.navSide ul {
  list-style-type: none;
}
.navSide ul li {
  margin: 15px 0px;
  text-align: initial;
}
.navSide ul li a:hover {
  color: #75b188;
}
.navSide ul li a:active {
  color: #75b188;
}
@media (max-width: 1024px) {
  .card-header {
    height: 100%;
  }

}
@media (min-width: 1024px) {
  .collapse {
    border-bottom: 1px solid rgba(238, 238, 238, 0.2);
  }

  .card-header-title {
    padding-bottom: 19px;
    padding-top: 18px;
    font-size: 15px;
  }
}
</style>
