<template
 class="back-ground-dark">
<div class="back-ground-dark" @click="hiddeMenuLogout">
    <br><br>

    <!-- SIDE BAR -->
    <div class="Nav_side">
      <NavSide/>
    </div>

    <!-- MY PROFILE -->
    <MyProfile
      v-if="this.section === 'profile'"
      :key="myProfileKey"
    ></MyProfile>

    <!-- MY SESSIONS -->
    <Sessions
      v-if="this.section === 'sessions'"
    />

    <!-- BANK SETTINGS -->
    <div class="is-flex container-bank">
      <Bank
        v-if="this.section === 'bank'"
      />
    </div>

  </div>
</template>

<script>
import Sessions from '@/views/panel/me/onlineSessions'
import Bank from '@/views/panel/me/BankDetails.vue'
import MyProfile from '@/views/panel/me/myProfile.vue'

import NavSide from '@/components/panel/profile/navSide.vue'

export default {
  name: 'Me',
  components: {
    NavSide,
    Sessions,
    Bank,
    MyProfile
  },
  metaInfo: {
    title: 'emitus | Me'
  },

  // DATA
  data () {
    return {
      showEmail: false,
      section: 'profile',
      showNotification: true,
      myProfileKey: 0
    }
  },

  // CREATED
  created () {
    window.scroll(0, 0)
    // Change Section
    this.notify.$on('changeSection', (section) => {
      this.section = section
    })
    this.notify.$on('changeSectionAvatarBank', (section) => {
      this.section = section
      document.getElementById('bank_id').click()
    })

    // Refresh MyProfile
    this.notify.$on('refreshMyProfile', () => {
      this.myProfileKey++
    })
  },

  // MOUNTED
  mounted () {
    const urlString = window.location.href
    const url = new URL(urlString)
    const section = url.searchParams.get('section')
    if (section && (section === 'sessions' || section === 'bank' || section === 'profile')) {
      this.section = section
    }

    // if (section === 'sessions') {
    //   document.getElementById('sessions_id').click()
    // } else if (section === 'bank') {
    //   document.getElementById('bank_id').click()
    // }
  },

  // METHODS
  methods: {
    hiddeMenuLogout () {
      document.getElementById('menu_logout_mobile').classList.add('disp-none')
    }
  },
  destroyed () {
    this.notify.$off('changeSection')
  }
}
</script>

<style scoped>

.back-ground-dark {
  background-color: rgb(0, 0, 0);
}

.save_all {
    width: 100vw;
    height: 70px;
    display: block;
    bottom: 0;
    position: fixed;
    background-color: black;
}

.disp-none {
  display: none;
}

.sectionTitle {
  margin-top:28px;
  margin-left: 15px;
}

.menuProfile {
  margin-right: 25px;
  margin-left: 25px;
}
.top{
  height: 68px;
  background-color: #ffffff;
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
}
.btn-profile-back {

  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  color: #929292;
  text-decoration: underline;
  font-size: 13px;
}
.btn-profile-back img {
  margin-right: 10px;
}
@media (min-width: 959px) {
  .btn-profile-back {
    position: fixed;
    top: 80px;
    left: 10px;
  }

  .back-ground-dark {
    background-color: #000;
  }
}

.not-online-sessions {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 4;
}
.title-navSide {
  font-size: 22px;
  line-height: 30px;
}
/*
.navSide {
  position: fixed;
  top: 190px;
  left: calc(55% - 590px);
}

.navSide a {
    color: #9A9A9A;
    cursor: pointer;
    display: block;
    transition: 0.3s;
    text-decoration: none;
}
.navSide ul li {
    margin: 15px 0px;
}
.navSide ul li a:hover {
    color: #69c493;
}
.navSide ul li a:active {
    color: #6E3DEA;
} */

.nav-side-online-sessions ul li a:hover {
    color: #75b188;
}
.nav-side-online-sessions ul li a:active {
    color: #75b188;
}
.seleccionado_onlineSessions {
    color: #25c16f!important;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #0C0D34;
}
.title-top-sessions img {
  margin-right: 26px;
  margin-left: 15px;
  width: 13.07px;
  height: 14px;
}
.title-top-sessions p {
  font-size: 20px;
  color: #0C0D34;
}
.text-nunito {
  font-size: 12px;
}

.select:not(.is-multiple) {
    height: 100%!important;
}
.field.has-addons .control:last-child:not(:only-child) .select select {
    height: 100%;
}
.disabledContent {
    pointer-events: none;
    opacity: 0.4;
}
#online_session .tabs ul {
  border-bottom: none!important;
}
#online_session .tabs a {
  justify-content: flex-end!important;
  border-bottom: none!important;
  padding: 0!important;
  margin: 15px 0 25px 0;
}
#online_session .tabs li.is-active a {
  border-bottom: none!important;
}
#online_session .tabs.is-centered ul {
  display: block!important;
}
.switch:not(.has-left-label) .control-label {
  padding: 0!important;
}
.switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 96%;
}
/* .container-bank {
  height: 100vh;
} */
@media (max-width: 1031px) {
  #online_session {
    width: 85%;
    margin: 0 auto;
  }
}
@media (min-width: 1031px) {

  .container-bank {
    /* width: 85%!important;
    margin-left: 23%; */
    width: 100%;
  }
}

</style>
