<template>
  <div id="#bankDetails bankDetails" class="container-bank-account">
    <div id="navSideProfile1c">
      <BankAccount :key="bankAccountKey"/>
    </div>
    <div
      id="navSideProfile2c"
      class="container-section-bank">
      <div
       class="section-bank ">
        <h2
         class="title-regular white">
         Tarjetas Bancarias
         <sup class="">(opcional)</sup> </h2>
        <p class="paragraph-content gray-ultra-light"> Estas tarjetas las podrá susar para contratar sesiones online. </p>
      </div>

      <!-- new card -->
      <div
        v-if="newCard"
        class="container-new-card">

        <div
          class="field mt-5"
          v-if="haveCards">

          <div
            class="title-new-card mb-6">
            <p
              class="text-basier-medium gray-ultra-light">
              Default payment method
            </p>
          </div>

          <b-field
            v-for="(card, idx) in myCards" :key="idx"
            class="card-detail-content-card is-flex is-justify-content-space-between mb-5"
          >

            <b-radio
              type="radio"
              v-model="radio" name="name" :native-value="card.Id"
              class="mr-3 is-flex">
              <div>
                <div class="container-card-details">
                  <img src="@/assets/images/panel/me/mastercard.svg" alt="">
                  <span class="text-nunito-Regular ml-4 white">{{card.Alias.replaceAll('X', '*').slice(8)}}</span>
                </div>
                <div>
                  <p class="mt-1 text-nunito white">EXPIRED ON</p>
                </div>
              </div>
            </b-radio>
            <div class="is-flex is-justify-content-flex-start">
              <div>
                <p class="ml-5 text-nunito-Regular white">
                  {{card.ExpirationDate.slice(0, -2)}}/{{card.ExpirationDate.slice(-2)}}
                </p>
              </div>
              <div class="container-delete-button">
                <a
                  class="btnDelete text-basier dark-purple-emitus link-text-purple"
                  @click="deleteCard(card.Id)">
                  Delete my card
                </a>
              </div>
            </div>
          </b-field>
        </div>
      </div>
      <!-- add new card -->
      <div
        v-if="linkNewCard"
        class="container-edit-bank mt-6">
        <a
          @click="clickFormNewCard"
          class="text-basier link-text-dark" >
          + Añadir tarjeta
        </a>
      </div>
      <!-- form new card -->
      <div
        v-if="showNewCard"
        class="field mt-5 content-add-new-card">
        <label
          class="label Basier-small-regular"
          style="color: #929292"
          >
          Credit card
        </label
        >

        <div class="control has-icons-left has-icons-right">
          <input
            v-model="mp_cardNumber"
            class="input text-nunito"
            style="background:none !important; color: #fff;"
            type=""
            placeholder="Car Number" />
          <span
            class="icon is-small is-right text-nunito">
            <img
              src="@/assets/images/booking/creditCard.svg"
              alt="" />
          </span>
          <div class="is-flex">
              <!-- @input="dateEpiredTDC" -->
            <input
              v-mask = "'##/##'"
              id="expiredTDC"
              maxlength="5"
              v-model="mp_cardExpirationDate"
              class="input text-nunito"
              type=""
              style="background:none !important; color: #fff;"
              placeholder="MM/ YY" />
            <input
              v-model="mp_cardCvx"
              class="input text-nunito"
              type=""
              style="background:none !important; color: #fff;"
              placeholder="CVV" />
          </div>
        </div>

        <div
          class="is-flex is-flex-direction-column is-align-items-center"
          style="margin-top: 21px; margin-bottom: 40px"
        >
          <label class="checkbox is-flex is-align-items-center">
            <p class="mt-5 paragraph-advertising dark-blue-emitus">
              Tu tarjeta quedará guardada para futuras reservas y posibles devoluciones. Podrás eliminarlo en cualquier momento.
            </p>
          </label>
        </div>

        <!-- C2A -->
        <b-button
          @click="nowWithNewCard"
          class="
            btn-pay-now
            button-primary-mini
          "
          >
          Guardar
        </b-button>
        <div v-if="loader">
          <Loading/>
        </div>

      </div>
    </div>
    <div class="h-desktop-800px"></div>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import BankAccount from '@/components/bankDetails/bankAccount.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },
  components: {
    BankAccount,
    Loading
  },
  data () {
    return {
      user: null,
      profile: {},
      linkNewCard: true,
      newCard: true,
      formNewCard: false,
      haveCards: false,
      myCards: null,
      radio: null,
      showNewCard: false,
      mp_UserId: null,
      currency: 'EUR',
      price: null,
      WalletId: null,
      CardId: null,
      mp_cardNumber: null,
      mp_accessKeyRef: null,
      mp_data: null,
      mp_cardExpirationDate: null,
      mp_cardCvx: null,
      loader: false,
      bankAccountKey: 0,
      baseURL: null

    }
  },
  async created () {
    // const expTDC = document.getElementById('expiredTDC')
    // IMask(expTDC, {
    //   mask: '00/00'
    // })
    // const expTDC = document.getElementById('expiredTDC')
    // IMask(document.getElementById('expiredTDC'), {
    //   mask: '00/00'
    // })

    // // or without UI element
    // const masked = IMask.PatternMasked({
    //   mask: '{#}000[aaa]/NIC-`*[**]'
    // })
    this.notify.$on('notificationWhenNotAccount', () => {
      this.danger("Hey! You don't have a bank account yet, add one and try again")
    })
    this.notify.$on('refreshBankAccount', () => {
      this.bankAccountKey++
    })
    this.notify.$on('updateUser', (user) => {
      this.user = user
    })
    this.notify.$on('updateProfile', (profile) => {
      this.profile = profile
    })
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const myCardsResponse = await MpService.getMyCards(idToken)
    const myCardsData = myCardsResponse.data
    const myCards = myCardsData.filter(e => e.Active === true)
    this.myCards = myCards
  },
  watch: {
    myCards: function () {
      if (this.myCards === null || this.myCards.length === 0) {
        this.radio = 'newCard'
        this.haveCards = false
      } else {
        this.haveCards = true
      }
    }
  },
  methods: {
    // dateEpiredTDC (text) {
    //   const expTDC = document.getElementById('expiredTDC')
    //   console.log(expTDC.value)
    //   IMask(expTDC.value, {
    //     mask: '00/00'
    //   })
    // },
    clickFormNewCard () {
      this.linkNewCard = false
      this.formNewCard = true
      this.showNewCard = true
    },
    clickNewCard () {
      this.formNewCard = false
      this.newCard = true
      this.linkNewCard = true
    },
    // Delete Card
    async deleteCard (CardId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await MpService.deleteCard(idToken, CardId)
      this.notify.$emit('refreshBankDetails')
    },
    // Post Card Info
    async postCardInfo (baseURL) {
      const obj = {}
      obj.accessKeyRef = this.mp_accessKeyRef
      obj.data = this.mp_data
      // obj.returnURL = 'https://emitus-panel.vercel.app/panel/booking'
      obj.cardNumber = this.mp_cardNumber
      const fixedExpDate = this.mp_cardExpirationDate.replace('/', '')
      obj.cardExpirationDate = fixedExpDate
      obj.cardCvx = this.mp_cardCvx

      try {
        const response = await MpService.postCardInfo(obj, baseURL)
        return response
      } catch (error) {
        console.log('Error from postCardInfo -->', error)
        this.danger(error)
        return false
      }
    },
    // Create a new card registration object
    async createCardRegistrationObject () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      try {
        const response = await MpService.createCardRegistrationObject(idToken)
        console.log('00', response)
        this.baseURL = response.data.CardRegistrationURL
        this.mp_accessKeyRef = response.data.AccessKey
        this.mp_data = response.data.PreregistrationData
        this.WalletId = response.data.WalletId
        this.mp_UserId = response.data.MpUserId
        this.mp_Currency = response.data.MpCurrency
        this.CardReg_Id = response.data.Id
        this.baseURL = response.data.CardRegistrationURL
        this.haveCards = true
        return true
      } catch (error) {
        console.log('Error from createCardRegistrationObject -->', error)
        this.danger(error)
        return false
      }
    },
    async nowWithNewCard () {
      // Create Card
      const CardRegistration = await this.createCardRegistrationObject() // Register object for card
      if (CardRegistration) {
        this.loader = true
        const RegistrationData = await this.postCardInfo(this.baseURL) // Tokenize Card info in 3rd party server
        if (RegistrationData) {
          const registeredCard = await this.putTokenData(RegistrationData.data) // Complete de card registration
          this.CardId = registeredCard.data.CardId
          this.showNewCard = false
          this.linkNewCard = true
          this.notify.$emit('refreshBankDetails')
          // Create PayIn
          // if (this.CardId) {
          //   const payIn = await this.payIn()
          //   console.log('---> PayIn', payIn)
          //   if (payIn.data.Status === 'SUCCEEDED') {
          //     const obj = {
          //       price: payIn.data.DebitedFunds.Amount / 100,
          //       currency: payIn.data.DebitedFunds.Currency,
          //       mppayinid: payIn.data.Id
          //     }
          //     this.bookingRequest(obj)
          //   }
          // }
        }
      } else {
        this.danger('Por favor elige otra fecha')
        this.loader = false
      }
    },
    // Put Token Data
    async putTokenData (RegistrationData) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const obj = {}
      obj.RegistrationData = RegistrationData
      const response = await MpService.putTokenData(idToken, obj, this.CardReg_Id)
      return response
    },
    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  },
  beforeDestroy () {
    this.notify.$off('refreshBankAccount')
    this.notify.$off('updateUser')
    this.notify.$off('updateProfile')
  }
}
</script>

<style scoped>

.card-detail-content-card {
  width: 100%;
  border-bottom: 1px solid rgba(238, 238, 238, 0.4);
  padding-bottom: 30px;
}
.content-add-new-card {
  max-width: 302px;
}

.container-card-details {
  width:100px;
}
.container-new-card .field-body > .field:not(.is-narrow) {
  justify-content: space-between;
  max-width: 302px;
  width: 100%;
}
.title-new-card {
  margin-top: 60px;
}
.title-new-card p {
  font-size: 13px!important;
}
.section-bank h2 sup {
  font-weight: normal;
  font-size: 13px;
  color: #929292;
}
.section-bank {
  font-weight: 300;
  font-size: 14px;
  color: #393939;
}

.container-delete-button {
  margin-top: -4px;
}

.btnDelete {
  margin-left: 17px;
  text-decoration: underline;
}

.container-new-card span {
  font-size: 14px;
}
.container-new-card p {
  font-size: 12px;
}
.container-new-card a {
  font-size: 12.5px;
}
.container-new-card img {
  width: 17px;
}
.container-bank-account {
  margin: 50px auto 110px auto;
}
.container-new-card .b-radio.radio input[type=radio] + .check{
  width: 15px!important;
  height: 15px!important;
  margin-right: 10px;
}
.input-date-card {
  max-width: 142px;
}
@media (max-width: 1024px) {
  .container-section-bank {
    width: 85%;
    margin: 120px auto;
  }
}
@media (min-width: 1024px) {

  .btnDelete {
    margin-left: 106px;
  }
  .section-bank {
    /* width: 85%; */
    margin: 0px auto;
  }
  .container-new-card {
    width: 400px!important;
  }
  .container-bank-account {
    /* width: 85%!important; */
    /*margin: 110px 100px;*/
    /* margin: 110px auto 110px 140px; */
    max-width: 734px;
  }
  .h-desktop-800px {
    height: 959px;
  }
}
</style>
