<template>
  <div id="bank-account">
    <div class="container_content_modal_withdraw_funds">
      <h1
        class="title-regular white">
        Cuenta de Banco
      </h1>
      <p
        class="paragraph-content gray-ultra-light pb-5">
        Agrega la cuenta bancaria donde quieres recibir los pagos de tus sesiones.
      </p>
      <ShowBankAccount :key="ShowBankAccountKey"/>
      <a
        v-if="btnAddAccount"
        @click="handlerAddAccount"
        class="mt-6 link-add-new-account text-basier link-text-dark"
      >+ Agregar nueva cuenta</a>

      <form id="formBankdetails" v-if="formBankAccount" class="mt-3 bank_form">

        <!-- Aquí pregunto tipo cuenta: IBAN / ABA / ... -->
        <!-- <b-field
          class="mt-3"
          label="Tipo de cuenta de banco"
          :message="messageBankFormat"
          :type="messageTypeBankFormat"
          v-on:focusin.native="clearValidateBankFormat"
          >
          <b-select
            v-on:focusout.native="ValidateBankFormat"
            v-model="BankFormat"
            placeholder="Selecciona una opción"
            expanded
            id="BankFormat"
          >
            <option value="IBAN">IBAN</option>
            <option value="US">US</option>
            <option value="GB">GB</option>
            <option value="CA">CA</option>
            <option value="OTHER">BIC/SWIFT</option>
           </b-select>
        </b-field> -->

        <!-- IBAN -->
        <b-field
          v-if="BankFormat === 'IBAN'"
          label="IBAN"
          :message="messageIBAN"
          :type="messageTypeIBAN"
          class="name"
        >
          <b-input
            id="IBAN"
            v-model="IBAN"
            autocomplete="off"
            placeholder="ES1234564544031234567890144"
            v-on:focusout.native="ValidateIBAN"
            v-on:focusin.native="clearValidateIBAN"
          >
          </b-input>
        </b-field>

        <!-- ABA -->
        <b-field
          v-if="BankFormat === 'US'"
          label="ABA"
          :message="messageABA"
          :type="messageTypeABA"
          class="name"
        >
          <b-input
            id="ABA"
            v-model="ABA"
            autocomplete="off"
            placeholder="071000288"
            v-on:focusout.native="ValidateABA"
            v-on:focusin.native="clearValidateABA"
          >
          </b-input>
        </b-field>

        <!-- US Account Number -->
        <b-field
          v-if="BankFormat === 'US'"
          label="Account Number"
          :message="messageUSAccountNumber"
          :type="messageTypeUSAccountNumber"
          class="name"
        >
          <b-input
            id="USAccountNumber"
            v-model="USAccountNumber"
            autocomplete="off"
            placeholder="11696419"
            v-on:focusout.native="ValidateUSAccountNumber"
            v-on:focusin.native="clearValidateUSAccountNumber"
          >
          </b-input>
        </b-field>

        <!-- SortCode GB -->
        <b-field
          v-if="BankFormat === 'GB'"
          label="SortCode"
          :message="messageSortCode"
          :type="messageTypeSortCode"
          class="name"
        >
          <b-input
            id="SortCode"
            v-model="SortCode"
            autocomplete="off"
            placeholder="010039"
            v-on:focusout.native="ValidateSortCode"
            v-on:focusin.native="clearValidateSortCode"
          >
          </b-input>
        </b-field>

        <!-- GB Account Number -->
        <b-field
          v-if="BankFormat === 'GB'"
          label="Número de cuenta"
          :message="messageGBAccountNumber"
          :type="messageTypeGBAccountNumber"
          class="name"
        >
          <b-input
            id="GBAccountNumber"
            v-model="GBAccountNumber"
            autocomplete="off"
            placeholder="11696419"
            v-on:focusout.native="ValidateGBAccountNumber"
            v-on:focusin.native="clearValidateGBAccountNumber"
          >
          </b-input>
        </b-field>

        <!-- BankName CA -->
        <b-field
          v-if="BankFormat === 'CA'"
          label="Nombre del banco"
          :message="messageBankName"
          :type="messageTypeBankName"
          class="name"
        >
          <b-input
            id="BankName"
            v-model="BankName"
            autocomplete="off"
            placeholder="The Big Bank"
            v-on:focusout.native="ValidateBankName"
            v-on:focusin.native="clearValidateBankName"
          >
          </b-input>
        </b-field>

        <!-- BranchCode CA -->
        <b-field
          v-if="BankFormat === 'CA'"
          label="Branch Code"
          :message="messageBranchCode"
          :type="messageTypeBranchCode"
          class="name"
        >
          <b-input
            id="BranchCode"
            v-model="BranchCode"
            autocomplete="off"
            placeholder="00152"
            v-on:focusout.native="ValidateBranchCode"
            v-on:focusin.native="clearValidateBranchCode"
          >
          </b-input>
        </b-field>

        <!-- InstitutionNumber CA -->
        <b-field
          v-if="BankFormat === 'CA'"
          label="Institution Number"
          :message="messageInstitutionNumber"
          :type="messageTypeInstitutionNumber"
          class="name"
        >
          <b-input
            id="InstitutionNumber"
            v-model="InstitutionNumber"
            autocomplete="off"
            placeholder="614"
            v-on:focusout.native="ValidateInstitutionNumber"
            v-on:focusin.native="clearValidateInstitutionNumber"
          >
          </b-input>
        </b-field>

        <!-- CA Account Number -->
        <b-field
          v-if="BankFormat === 'CA'"
          label="Account Number"
          :message="messageCAAccountNumber"
          :type="messageTypeCAAccountNumber"
          class="name"
        >
          <b-input
            id="CAAccountNumber"
            v-model="CAAccountNumber"
            autocomplete="off"
            placeholder="11696419"
            v-on:focusout.native="ValidateCAAccountNumber"
            v-on:focusin.native="clearValidateCAAccountNumber"
          >
          </b-input>
        </b-field>

        <!-- BIC -->
        <b-field
          v-if="BankFormat === 'OTHER'"
          label="BIC/SWIFT"
          :message="messageBIC"
          :type="messageTypeBIC"
          class="name"
        >
          <b-input
            id="BIC"
            v-model="BIC"
            autocomplete="off"
            placeholder="BNMXMXMMXXX"
            v-on:focusout.native="ValidateBIC"
            v-on:focusin.native="clearValidateBIC"
          >
          </b-input>
        </b-field>

        <!-- OTHER Account Number -->
        <b-field
          v-if="BankFormat === 'OTHER'"
          label="Account Number"
          :message="messageOTHERAccountNumber"
          :type="messageTypeOTHERAccountNumber"
          class="name"
        >
          <b-input
            id="OTHERAccountNumber"
            v-model="OTHERAccountNumber"
            autocomplete="off"
            placeholder="11696419"
            v-on:focusout.native="ValidateOTHERAccountNumber"
            v-on:focusin.native="clearValidateOTHERAccountNumber"
          >
          </b-input>
        </b-field>

        <p
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          class="subHeading"
        >Dirección de {{user.firstname + ' ' + user.lastname}}</p>

        <p
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          class="subHeadingText"
        >(Dueño de la cuenta bancaria)</p>

        <!-- AddressLine1 -->
        <b-field
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          label="Calle y número"
          :message="messageAddressLine1"
          :type="messageTypeAddressLine1"
          class="name address-input mt-3"
        >
          <b-input
            id="AddressLine1"
            v-model="AddressLine1"
            autocomplete="off"
            :placeholder="placeholderAddress"
            v-on:focusout.native="ValidateAddressLine1"
            v-on:focusin.native="clearValidateAddressLine1"
          >
          </b-input>
        </b-field>

        <!-- City -->
        <b-field
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          label="Ciudad"
          :message="messageCity"
          :type="messageTypeCity"
          class="name mt-3"
        >
          <b-input
            id="City"
            v-model="City"
            autocomplete="off"
            :placeholder="placeholderCity"
            v-on:focusout.native="ValidateCity"
            v-on:focusin.native="clearValidateCity"
          >
          </b-input>
        </b-field>

        <!-- Region -->
        <b-field
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          label="Provincia"
          :message="messageRegion"
          :type="messageTypeRegion"
          class="name mt-3"
        >
          <b-input
            id="Region"
            v-model="Region"
            autocomplete="off"
            :placeholder="placeholderRegion"
            v-on:focusout.native="ValidateRegion"
            v-on:focusin.native="clearValidateRegion"
          >
          </b-input>
        </b-field>

        <!-- PostalCode -->
        <b-field
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          label="Código Postal"
          :message="messagePostalCode"
          :type="messageTypePostalCode"
          class="name mt-4"
        >
          <b-input
            id="PostalCode"
            v-model="PostalCode"
            autocomplete="off"
            :placeholder="placeholderPostalCode"
            v-on:focusout.native="ValidatePostalCode"
            v-on:focusin.native="clearValidatePostalCode"
          >
          </b-input>
        </b-field>

        <!--- Country-->
        <!-- <b-field
          v-if="BankFormat === 'IBAN' || BankFormat === 'US' || BankFormat === 'GB' || BankFormat === 'CA' || BankFormat === 'OTHER'"
          class="mt-3"
          label="Country"
          :message="messageCountry"
          :type="messageTypeCountry"
          v-on:focusin.native="clearValidateCountry"
          >
          <b-select
            v-on:focusout.native="ValidateCountry"
            v-model="Country"
            placeholder="Choose country"
            icon="earth"
            expanded
            id="Country"
          >
            <optgroup label="Most common">
              <option value="United States">United States</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Germany">Germany</option>
              <option value="France">France</option>
              <option value="Italy">Italy</option>
              <option value="Spain">Spain</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Japan">Japan</option>
              <option value="India">India</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Poland">Poland</option>
              <option value="Canada">Canada</option>
            </optgroup>

            <optgroup label="Other">
              <option
                v-for="(country, idx) in countries"
                :key="idx"
                :value="country"
              >
                {{ country }}
              </option>
            </optgroup>
          </b-select>
        </b-field> -->

        <!-- CTA  IBAN -->
        <button
          v-if="BankFormat === 'IBAN'"
          class="button-primary-extra-medium mt-6"
          @click.prevent="createIBANBankAccount"
        >Guardar</button>

        <!-- CTA  US -->
        <button
          v-if="BankFormat === 'US'"
          class="button-primary-extra-medium mt-6"
          @click.prevent="createUSBankAccount"
        >Guardar datos bancarios</button>

        <!-- CTA  GB -->
        <button
          v-if="BankFormat === 'GB'"
          class="button-primary-extra-medium mt-6"
          @click.prevent="createGBBankAccount"
        >Guardar datos bancarios</button>

        <!-- CTA  CA -->
        <button
          v-if="BankFormat === 'CA'"
          class="button-primary-extra-medium mt-6"
          @click.prevent="createCABankAccount"
        >Guardar datos bancarios</button>

        <!-- CTA  OTHER -->
        <button
          v-if="BankFormat === 'OTHER'"
          class="button-primary-extra-medium mt-6"
          @click.prevent="createOTHERBankAccount"
        >Guardar datos bancarios</button>

      </form>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
import ShowBankAccount from '@/components/bankDetails/showBankAccount.vue'
import UserService from '@/services/UserService'

export default {
  name: 'Bank',
  components: {
    ShowBankAccount
  },
  data () {
    return {
      user: null,
      // To be sent
      AddressLine1: null,
      City: null,
      Region: null,
      PostalCode: null,
      Country: 'Spain',
      IBAN: null,
      USAccountNumber: null,
      ABA: null,
      GBAccountNumber: null,
      SortCode: null,
      BranchCode: null,
      InstitutionNumber: null,
      BankName: null,
      CAAccountNumber: null,
      BIC: null,
      OTHERAccountNumber: null,

      BankFormat: 'IBAN',

      // Messages
      messageCountry: '',
      messageTypeCountry: '',
      messageBankFormat: '',
      messageTypeBankFormat: '',
      messageIBAN: '',
      messageTypeIBAN: '',
      messageABA: '',
      messageTypeABA: '',
      messageUSAccountNumber: '',
      messageTypeUSAccountNumber: '',
      messageSortCode: '',
      messageTypeSortCode: '',
      messageGBAccountNumber: '',
      messageTypeGBAccountNumber: '',

      messageBranchCode: '',
      messageTypeBranchCode: '',
      messageInstitutionNumber: '',
      messageTypeInstitutionNumber: '',
      messageBankName: '',
      messageTypeBankName: '',
      messageCAAccountNumber: '',
      messageTypeCAAccountNumber: '',

      messageAddressLine1: '',
      messageTypeAddressLine1: '',
      messageRegion: '',
      messageTypeRegion: '',
      messagePostalCode: '',
      messageTypePostalCode: '',
      messageCity: '',
      messageTypeCity: '',

      obj_: null,
      bankAccounts: [],
      formBankAccount: false,
      btnAddAccount: false,
      ShowBankAccountKey: 0,

      placeholderAddress: null,
      placeholderCity: null,
      placeholderRegion: null,
      placeholderPostalCode: null,

      // About data
      locale: undefined, // Browser locale,
      countries: [
        'Andorra',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahrein',
        'Belgium',
        'Benin',
        'Bhutan',
        'Brazil',
        'British Indian Ocean Territory',
        'Bulgaria',
        'Chile',
        'China',
        'Canada',
        'Costa Rica',
        'Cote Ivoire',
        'Comoros',
        'Croatia',
        'Curacao',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Estonia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French Guiana',
        'Georgia',
        'Germany',
        'Gibraltar',
        'Greece',
        'Guadeloupe',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'Ireland',
        'Israel',
        'Italy',
        'Japan',
        'Jordan',
        'Kenya',
        'Korea',
        'Kiribati',
        'Latvia',
        'Lesotho',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Mayotte',
        'Martinique',
        'Malta',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Morocco',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Norway',
        'Oman',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Rwanda',
        'Saint Pierre And Miquelon',
        'San Marino',
        'Saudi Arabia',
        'Saint Barthelemy',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Solomon Islands',
        'Spain',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Taiwan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Turkey',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Vietnam',
        'Wallis And Futuna'
      ],

      // Operations
      valid: true,
      bindProps: {
        mode: 'international',
        preferredCountries: ['US', 'ES', 'DE', 'GB'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off'
      }
    }
  },
  async created () {
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    MpService.getMyBankAccounts(idToken)
      .then(BankAccountsArr => {
        this.bankAccounts = BankAccountsArr.data
        if (this.bankAccounts.length === 0) {
          this.formBankAccount = true
        } else {
          this.formBankAccount = false
          this.btnAddAccount = true
        }
      })
    UserService.getCurrentUser(idToken)
      .then(user => {
        this.user = user.data
      })
    // this.notify.$on('NotificationWhenNotHaveAccount', () => {
    //   this.notificationWhenNotHaveAccount("Hey! You don't have a bank account yet, add one and try again")
    // })
    this.notify.$on('refreshBankAccount', () => {
      this.ShowBankAccountKey++
    })
  },

  watch: {
    BankFormat: function () {
      if (this.BankFormat === 'IBAN') {
        this.placeholderAddress = '36 La Rue Di Marie'
        this.placeholderCity = 'Paris'
        this.placeholderRegion = 'Ile de Frog'
        this.placeholderPostalCode = '75010'
      } else if (this.BankFormat === 'US') {
        this.placeholderAddress = '4281 Pine Garden Lane'
        this.placeholderCity = 'Atlanta'
        this.placeholderRegion = 'Georgia'
        this.placeholderPostalCode = '30328'
      } else if (this.BankFormat === 'GB') {
        this.placeholderAddress = '41 Caxton Place'
        this.placeholderCity = 'BYTHORN'
        this.placeholderRegion = ''
        this.placeholderPostalCode = 'PE18 9RU'
      } else if (this.BankFormat === 'CA') {
        this.placeholderAddress = '3273 Albert Street'
        this.placeholderCity = 'Kitchener'
        this.placeholderRegion = 'Ontario'
        this.placeholderPostalCode = 'N2L 3V2'
      } else if (this.BankFormat === 'OTHER') {
        this.placeholderAddress = 'Obregon 62'
        this.placeholderCity = 'Morelos'
        this.placeholderRegion = 'Cuernavaca'
        this.placeholderPostalCode = '62170'
      }
    }
  },

  // METHODS

  methods: {
    // Create IBAN Bank Account IBAN
    async createIBANBankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Region: this.Region,
        PostalCode: this.PostalCode,
        Country: this.Country,
        IBAN: this.IBAN
      }
      try {
        const response = await MpService.createIBANBankAccount(idToken, obj)
        if (response.data.bankAccount.Active) {
          this.notification(`Tajeta ${response.data.bankAccount.IBAN} guardada`)
          this.formBankAccount = false
          this.btnAddAccount = true
          this.ShowBankAccountKey++
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
      }
    },

    // Create US Bank Account
    async createUSBankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Region: this.Region,
        PostalCode: this.PostalCode,
        Country: this.Country,
        USAccountNumber: this.USAccountNumber,
        ABA: this.ABA
      }
      // console.log(obj, 'aaaaa')
      try {
        const response = await MpService.createUSBankAccount(idToken, obj)
        // console.log('Response from createIBANBankAccount', response)
        // console.log('Response from createIBANBankAccount ------>>>>>', response)
        if (response.data.bankAccount.Active) {
          this.notification(`Tajeta ${response.data.bankAccount.IBAN} guardada`)
          this.formBankAccount = false
          this.btnAddAccount = true
          this.ShowBankAccountKey++
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from createUSBankAccount -->', error)
      }
    },

    // Create GB Bank Account
    async createGBBankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Country: this.Country,
        PostalCode: this.PostalCode,
        GBAccountNumber: this.GBAccountNumber,
        SortCode: this.SortCode
      }
      // console.log(obj, 'aaaaa')
      try {
        const response = await MpService.createGBBankAccount(idToken, obj)
        // console.log('Response from createIBANBankAccount', response)
        // console.log('Response from createIBANBankAccount ------>>>>>', response)
        if (response.data.bankAccount.Active) {
          this.notification(`Tajeta ${response.data.bankAccount.IBAN} guardada`)
          this.formBankAccount = false
          this.btnAddAccount = true
          this.ShowBankAccountKey++
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from createGBBankAccount -->', error)
      }
    },

    // Create GB Bank Account
    async createCABankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Country: this.Country,
        Region: this.Region,
        PostalCode: this.PostalCode,
        InstitutionNumber: this.InstitutionNumber,
        BankName: this.BankName,
        CAAccountNumber: this.CAAccountNumber,
        BranchCode: this.BranchCode
      }
      // console.log(obj, 'aaaaa')
      try {
        const response = await MpService.createCABankAccount(idToken, obj)
        // console.log('Response from createIBANBankAccount', response)
        // console.log('Response from createIBANBankAccount ------>>>>>', response)
        if (response.data.bankAccount.Active) {
          this.notification(`Tajeta ${response.data.bankAccount.IBAN} guardada`)
          this.formBankAccount = false
          this.btnAddAccount = true
          this.ShowBankAccountKey++
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from createCABankAccount -->', error)
      }
    },

    // Create OTHER Bank Account
    async createOTHERBankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Country: this.Country,
        Region: this.Region,
        PostalCode: this.PostalCode,
        OTHERAccountNumber: this.OTHERAccountNumber,
        BIC: this.BIC
      }
      // console.log(obj, 'aaaaa')
      try {
        const response = await MpService.createOTHERBankAccount(idToken, obj)
        // console.log('Response from createIBANBankAccount', response)
        // console.log('Response from createIBANBankAccount ------>>>>>', response)
        if (response.data.bankAccount.Active) {
          this.notification(`Tajeta ${response.data.bankAccount.IBAN} guardada`)
          this.formBankAccount = false
          this.btnAddAccount = true
          this.ShowBankAccountKey++
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from createOTHERBankAccount -->', error)
      }
    },

    // Validations

    ValidateAddressLine1 () {
      if (!this.AddressLine1) {
        this.messageAddressLine1 = 'Te falta este campo'
        this.messageTypeAddressLine1 = 'is-primary'
        document.getElementById('AddressLine1').classList.add('invalid-input')
        return false
      } else {
        this.messageAddressLine1 = ''
        this.messageTypeAddressLine1 = ''
        return true
      }
    },
    ValidateCity () {
      if (!this.City) {
        this.messageCity = 'Te falta este campo'
        this.messageTypeCity = 'is-primary'
        document.getElementById('City').classList.add('invalid-input')
        return false
      } else {
        this.messageCity = ''
        this.messageTypeCity = ''
        return true
      }
    },
    ValidateRegion () {
      if (!this.Region) {
        this.messageRegion = 'Te falta este campo'
        this.messageTypeRegion = 'is-primary'
        document.getElementById('Region').classList.add('invalid-input')
        return false
      } else {
        this.messageRegion = ''
        this.messageTypeRegion = ''
        return true
      }
    },
    ValidatePostalCode () {
      if (!this.PostalCode) {
        this.messagePostalCode = 'Te falta este campo'
        this.messageTypePostalCode = 'is-primary'
        document.getElementById('PostalCode').classList.add('invalid-input')
        return false
      } else {
        this.messagePostalCode = ''
        this.messageTypePostalCode = ''
        return true
      }
    },
    ValidateCountry () {
      if (!this.Country) {
        this.messageCountry = 'Te falta este campo'
        this.messageTypeCountry = 'is-primary'
        document.getElementById('Country').classList.add('invalid-input')
        return false
      } else {
        this.messageCountry = ''
        this.messageTypeCountry = ''
        return true
      }
    },
    ValidateBankFormat () {
      if (!this.BankFormat) {
        this.messageBankFormat = 'Te falta este campo'
        this.messageTypeBankFormat = 'is-primary'
        document.getElementById('Country').classList.add('invalid-input')
        return false
      } else {
        this.messageBankFormat = ''
        this.messageTypeBankFormat = ''
        return true
      }
    },
    ValidateIBAN () {
      if (!this.IBAN) {
        this.messageIBAN = 'Te falta este campo'
        this.messageTypeIBAN = 'is-primary'
        document.getElementById('IBAN').classList.add('invalid-input')
        return false
      } else {
        this.messageIBAN = ''
        this.messageTypeIBAN = ''
        return true
      }
    },

    ValidateABA () {
      const isnum = /^\d+$/.test(this.ABA)
      if (!this.ABA) {
        this.messageABA = 'Te falta este campo'
        this.messageTypeABA = 'is-primary'
        document.getElementById('ABA').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageABA = 'ABA must be numbers only'
        this.messageTypeABA = 'is-primary'
        document.getElementById('ABA').classList.add('invalid-input')
      } else if (this.ABA.length !== 9) {
        this.messageABA = 'ABA number must be 9 digits long'
        this.messageTypeABA = 'is-primary'
        document.getElementById('ABA').classList.add('invalid-input')
      } else {
        this.messageABA = ''
        this.messageTypeABA = ''
        return true
      }
    },

    ValidateUSAccountNumber () {
      const isnum = /^\d+$/.test(this.USAccountNumber)
      if (!this.USAccountNumber) {
        this.messageUSAccountNumber = 'Te falta este campo'
        this.messageTypeUSAccountNumber = 'is-primary'
        document.getElementById('USAccountNumber').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageUSAccountNumber = 'US Bank Account must be numbers only'
        this.messageTypeUSAccountNumber = 'is-primary'
        document.getElementById('USAccountNumber').classList.add('invalid-input')
      } else {
        this.messageUSAccountNumber = ''
        this.messageTypeUSAccountNumber = ''
        return true
      }
    },

    ValidateSortCode () {
      const isnum = /^\d+$/.test(this.SortCode)
      if (!this.SortCode) {
        this.messageSortCode = 'Te falta este campo'
        this.messageTypeSortCode = 'is-primary'
        document.getElementById('SortCode').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageSortCode = 'SortCode must be numbers only'
        this.messageTypeSortCode = 'is-primary'
        document.getElementById('SortCode').classList.add('invalid-input')
      } else if (this.SortCode.length !== 6) {
        this.messageSortCode = 'SortCode number must be 6 digits long'
        this.messageTypeSortCode = 'is-primary'
        document.getElementById('SortCode').classList.add('invalid-input')
      } else {
        this.messageSortCode = ''
        this.messageTypeSortCode = ''
        return true
      }
    },

    ValidateGBAccountNumber () {
      const isnum = /^\d+$/.test(this.GBAccountNumber)
      if (!this.GBAccountNumber) {
        this.messageGBAccountNumber = 'Te falta este campo'
        this.messageTypeGBAccountNumber = 'is-primary'
        document.getElementById('GBAccountNumber').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageGBAccountNumber = 'GB Bank Account must be numbers only'
        this.messageTypeGBAccountNumber = 'is-primary'
        document.getElementById('GBAccountNumber').classList.add('invalid-input')
      } else if (this.GBAccountNumber.length !== 8) {
        this.messageGBAccountNumber = 'GB Bank account number must be 8 digits long'
        this.messageTypeGBAccountNumber = 'is-primary'
        document.getElementById('GBAccountNumber').classList.add('invalid-input')
      } else {
        this.messageGBAccountNumber = ''
        this.messageTypeGBAccountNumber = ''
        return true
      }
    },

    ValidateBranchCode () {
      const isnum = /^\d+$/.test(this.BranchCode)
      if (!this.BranchCode) {
        this.messageBranchCode = 'Te falta este campo'
        this.messageTypeBranchCode = 'is-primary'
        document.getElementById('BranchCode').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageBranchCode = 'Branch Code must be numbers only'
        this.messageTypeBranchCode = 'is-primary'
        document.getElementById('BranchCode').classList.add('invalid-input')
      } else if (this.BranchCode.length !== 5) {
        this.messageBranchCode = 'Branch Code number must be 5 digits long'
        this.messageTypeBranchCode = 'is-primary'
        document.getElementById('BranchCode').classList.add('invalid-input')
      } else {
        this.messageBranchCode = ''
        this.messageTypeBranchCode = ''
        return true
      }
    },

    ValidateInstitutionNumber () {
      const isnum = /^\d+$/.test(this.InstitutionNumber)
      if (!this.InstitutionNumber) {
        this.messageInstitutionNumber = 'Te falta este campo'
        this.messageTypeInstitutionNumber = 'is-primary'
        document.getElementById('InstitutionNumber').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageInstitutionNumber = 'Institution Number must be numbers only'
        this.messageTypeInstitutionNumber = 'is-primary'
        document.getElementById('InstitutionNumber').classList.add('invalid-input')
      } else if (this.InstitutionNumber.length !== 3) {
        this.messageInstitutionNumber = 'Institution Number number must be 3 digits long'
        this.messageTypeInstitutionNumber = 'is-primary'
        document.getElementById('InstitutionNumber').classList.add('invalid-input')
      } else {
        this.messageInstitutionNumber = ''
        this.messageTypeInstitutionNumber = ''
        return true
      }
    },

    ValidateBankName () {
      if (!this.BankName) {
        this.messageBankName = 'Te falta este campo'
        this.messageTypeBankName = 'is-primary'
        document.getElementById('BankName').classList.add('invalid-input')
        return false
      } else if (this.BankName.length >= 50) {
        this.messageBankName = 'Maximum 50 characters long'
        this.messageTypeBankName = 'is-primary'
        document.getElementById('BankName').classList.add('invalid-input')
      } else {
        this.messageBankName = ''
        this.messageTypeBankName = ''
        return true
      }
    },

    ValidateCAAccountNumber () {
      const isnum = /^\d+$/.test(this.CAAccountNumber)
      if (!this.CAAccountNumber) {
        this.messageCAAccountNumber = 'Te falta este campo'
        this.messageTypeCAAccountNumber = 'is-primary'
        document.getElementById('CAAccountNumber').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageCAAccountNumber = 'CA Bank Account must be numbers only'
        this.messageTypeCAAccountNumber = 'is-primary'
        document.getElementById('CAAccountNumber').classList.add('invalid-input')
      } else if (this.CAAccountNumber.length > 35 || this.CAAccountNumber.length < 7) {
        this.messageCAAccountNumber = 'Must be between 7-35 digits long'
        this.messageTypeCAAccountNumber = 'is-primary'
        document.getElementById('CAAccountNumber').classList.add('invalid-input')
      } else {
        this.messageCAAccountNumber = ''
        this.messageTypeCAAccountNumber = ''
        return true
      }
    },

    ValidateBIC () {
      if (!this.BIC) {
        this.messageBIC = 'Te falta este campo'
        this.messageTypeBIC = 'is-primary'
        document.getElementById('BIC').classList.add('invalid-input')
        return false
      } else {
        this.messageBIC = ''
        this.messageTypeBIC = ''
        return true
      }
    },

    ValidateOTHERAccountNumber () {
      const isnum = /^\d+$/.test(this.OTHERAccountNumber)
      if (!this.OTHERAccountNumber) {
        this.messageOTHERAccountNumber = 'Te falta este campo'
        this.messageTypeOTHERAccountNumber = 'is-primary'
        document.getElementById('OTHERAccountNumber').classList.add('invalid-input')
        return false
      } else if (!isnum) {
        this.messageOTHERAccountNumber = 'Bank Account must be numbers only'
        this.messageTypeOTHERAccountNumber = 'is-primary'
        document.getElementById('OTHERAccountNumber').classList.add('invalid-input')
      } else if (this.OTHERAccountNumber.length > 35 || this.OTHERAccountNumber.length < 7) {
        this.messageOTHERAccountNumber = 'Must be between 7-35 digits long'
        this.messageTypeOTHERAccountNumber = 'is-primary'
        document.getElementById('OTHERAccountNumber').classList.add('invalid-input')
      } else {
        this.messageOTHERAccountNumber = ''
        this.messageTypeOTHERAccountNumber = ''
        return true
      }
    },

    handlerAddAccount () {
      this.formBankAccount = true
      this.btnAddAccount = false
    },

    // Notifications

    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    },
    // notification when I don't have an account
    notificationWhenNotHaveAccount (msg) {
      this.$buefy.toast.open({
        duration: 6000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    // Clear Validations

    clearValidateAddressLine1 () {
      this.messageAddressLine1 = ''
      this.messageTypeAddressLine1 = ''
      document.getElementById('AddressLine1').classList.remove('invalid-input')
    },
    clearValidateCity () {
      this.messageCity = ''
      this.messageTypeCity = ''
      document.getElementById('City').classList.remove('invalid-input')
    },
    clearValidateRegion () {
      this.messageRegion = ''
      this.messageTypeRegion = ''
      document.getElementById('Region').classList.remove('invalid-input')
    },
    clearValidatePostalCode () {
      this.messagePostalCode = ''
      this.messageTypePostalCode = ''
      document.getElementById('PostalCode').classList.remove('invalid-input')
    },
    clearValidateCountry () {
      this.messageCountry = ''
      this.messageTypeCountry = ''
      document.getElementById('Country').classList.remove('invalid-input')
    },
    clearValidateBankFormat () {
      this.messageBankFormat = ''
      this.messageTypeBankFormat = ''
      document.getElementById('BankFormat').classList.remove('invalid-input')
    },
    clearValidateIBAN () {
      this.messageIBAN = ''
      this.messageTypeIBAN = ''
      document.getElementById('IBAN').classList.remove('invalid-input')
    },
    clearValidateABA () {
      this.messageABA = ''
      this.messageTypeABA = ''
      document.getElementById('ABA').classList.remove('invalid-input')
    },
    clearValidateUSAccountNumber () {
      this.messageUSAccountNumber = ''
      this.messageTypeUSAccountNumber = ''
      document.getElementById('USAccountNumber').classList.remove('invalid-input')
    },
    clearValidateSortCode () {
      this.messageSortCode = ''
      this.messageTypeSortCode = ''
      document.getElementById('SortCode').classList.remove('invalid-input')
    },
    clearValidateGBAccountNumber () {
      this.messageGBAccountNumber = ''
      this.messageTypeGBAccountNumber = ''
      document.getElementById('GBAccountNumber').classList.remove('invalid-input')
    },
    clearValidateBranchCode () {
      this.messageBranchCode = ''
      this.messageTypeBranchCode = ''
      document.getElementById('BranchCode').classList.remove('invalid-input')
    },
    clearValidateInstitutionNumber () {
      this.messageInstitutionNumber = ''
      this.messageTypeInstitutionNumber = ''
      document.getElementById('InstitutionNumber').classList.remove('invalid-input')
    },
    clearValidateBankName () {
      this.messageBankName = ''
      this.messageTypeBankName = ''
      document.getElementById('BankName').classList.remove('invalid-input')
    },
    clearValidateCAAccountNumber () {
      this.messageCAAccountNumber = ''
      this.messageTypeCAAccountNumber = ''
      document.getElementById('CAAccountNumber').classList.remove('invalid-input')
    },
    clearValidateBIC () {
      this.messageBIC = ''
      this.messageTypeBIC = ''
      document.getElementById('BIC').classList.remove('invalid-input')
    },
    clearValidateOTHERAccountNumber () {
      this.messageOTHERAccountNumber = ''
      this.messageTypeOTHERAccountNumber = ''
      document.getElementById('OTHERAccountNumber').classList.remove('invalid-input')
    }

  },
  beforeDestroy () {
    this.notify.$off('refreshBankAccount')
    this.notify.$off('notification_when_not_have_account')
  }
}
</script>

<style>
#BankFormat option {
  color: #fff!important;
}
.subHeading {
  color: #fff;
  font-size: 18px !important;
  margin-top: 35px;
}

.subHeadingText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-bottom: 15px;
}

#formBankdetails select {
  background: #000;
}
#formBankdetails select optgroup option{
  color: #fff!important;
}
.link-add-new-account {
  font-size: 14px;
}
.address-input {
  margin-bottom: 0px !important;
}
.container_content_modal_withdraw_funds {
  width: 85%;
  height: 100%;
  margin: 64px auto 60px auto;
  display: flex;
  flex-direction: column;
}

.container_content_modal_withdraw_funds label {
  font-weight: normal;
  font-size: 10px;
  color: #929292;
}
.container_content_modal_withdraw_funds .select select {
  width: 100%;
  border: 1px solid rgba(238, 238, 238, 0.2) !important;
  color: #fff !important;
}

.container_content_modal_withdraw_funds .input input {
  border: 1px solid rgba(238, 238, 238, 0.2) !important;
  color: #fff !important;
  background-color: #0B0C33 !important;
}

@media (min-width: 1024px) {
  .container_content_modal_withdraw_funds {
    width: 100%;
    margin-top: 90px;
  }
  .container_content_modal_withdraw_funds h1 {
    font-size: 36px;
  }
  .container_content_modal_withdraw_funds p {
    max-width: 284px;
    font-size: 14px;
  }
  .container_content_modal_withdraw_funds label {
    font-size: 12px;
  }
  .container_content_modal_withdraw_funds .select {
    max-width: 284px;
  }
  .container_content_modal_withdraw_funds input {
    max-width: 284px;
    background-color: #000 !important;
    border: 1px solid rgba(238, 238, 238, 0.2) !important;
    color: #fff !important;
  }

  .content-add-new-card input {
    border: 1px solid rgba(238, 238, 238, 0.2) !important;
    color: #fff !important;
  }
}

@media (max-width: 1024px) {

  .content-add-new-card input{
    border: 1px solid rgba(238, 238, 238, 0.2) !important;
    color: #fff !important;
  }

  .container_content_modal_withdraw_funds input {
    max-width: 302px;
    background-color: #000 !important;
    border: 1px solid rgba(238, 238, 238, 0.2) !important;
    color: #fff !important;
  }
}
 </style>
