<template>
  <div id="profile-dark">

    <!----------------------------------
    A) GENERAL--------------------------
    ----------------------------------->
    <div class="container-content-dash">

      <!-- A-1) Glance -->
      <div
        class="
          container-picture
          is-flex
          is-justify-content-center
          is-flex-direction-styles-responsive
          is-align-items-center
        "
        >
        <div
          v-if="profile"
          class="control is-hidden-desktop"
          >
          <div
            class="is-flex"
          >
            <p
              v-if="user.firstname"
              class="white name-profesional text-tranformation-capitalize">
              {{user.firstname}} {{user.lastname}}
            </p>
            <sup
              v-if="this.status !== 'Review'">
              <div :class="showp === true ? 'circle-available-mini' : 'circle-disable-mini'">
              </div>
            </sup>
            <sup v-if="this.status === 'Review'"><div class="circle-review-mini"></div></sup>
          </div>
          <p
            v-if="profile.title"
            class="text-basier is-size-text-normal gray-ultra-light is-flex is-justify-content-center text-tranformation-capitalize mt-2">
            {{profile.title}}
          </p>
        </div>

        <!-- avatar -->
        <div class="picwrap" style="position: relative;">
          <b-image
            :src="avatarp"
            alt="My pic"
            ratio="200by200"
            rounded
          ></b-image>
          <div
            class="bottom-edit-photo"
            @click="ClickShowModalSelect"
          >
            <div
              class="content-bottom-edit-photo is-flex">
              <div
                class="is-flex icon-text-edit"
              >
                <p
                  class="text-basier mr-2">
                  editar
                </p>
                <span> | </span>
                <img
                    class="ml-2"
                    src="https://emituscdn.com/camera-icon.svg">
              </div>
              <!-- <div v-if="this.status !== 'Review'" :class="showp === true ? 'circle-available' : 'circle-disable'"></div>
              <div v-if="this.status === 'Review'" class="circle-review"></div> -->
            </div>
          </div>
        </div>

        <!-- copy public profile link -->
        <div
          v-if="profile"
          class="ml-6 mt-5 is-hidden-touch">
          <p
            v-if="user.firstname"
            class="text-basier-medium white name-profesional text-tranformation-capitalize">
            {{user.firstname}} {{user.lastname}}
          </p>
          <p
            v-if="profile.title"
            class="text-basier gray-ultra-light is-size-medium-text mt-2 mb-4">
            {{profile.title}}
          </p>
          <router-link
            id="only-professional-1"
            class="link-profesional-info mb-2 text-basier"
            target="_blank" :to="'/' + username">
            {{linkHost}}/{{usernamep}}
          </router-link>
          <div
            class="bottom-separation mt-5 mb-5">
          </div>
          <div
            class="is-flex mt-3"
            style="gap: 15px;">
            <!-- https://api.whatsapp.com/send?text=Entra%20a%20mi%20perfil%20https://prepagos69.com/" + city + "/" + category + "/" + profile.slug, target="_blank" -->
            <a id="only-professional-2" @click="showModalShareProfile" class="btn-share-profile"><span class="text-basier-medium">Compartir Perfil</span></a>
            <!-- <a :href="'https://api.whatsapp.com/send?text=Entra%20a%20mi%20perfil%20https://' + linkHost + '/' + usernamep" target="_blank" id="btn_codivy" class="btn-share-profile"><span class="text-basier-medium">Share Profile</span></a> -->
            <b-button id="btn_codivy" @click="copy" class="btn-copy-profile"><span class="text-basier-medium">Copiar link</span></b-button>
          </div>
        </div>
        <!-- link for public profile -->
        <!-- <div class="mt-2 is-flex is-align-items-center link-profile-user is-flex-direction-column">
          <span>@{{ firstnamep }} · {{ status }} </span>
          <a :href="'/' + usernamep" target="_blank">emitus.com/{{usernamep}}</a>
        </div> -->

      </div>

    </div>

    <!-- modal edit avatar -->
    <div v-if="showModalSelect" class="modal-select-edit-avatar">
      <div @click="hiddeModalEditAvatar" class="bg-modal-select-edit-avatar"></div>
      <div class="container-select-edit-avatar is-flex is-flex-direction-column">
        <div
         key="" style="text-align: end; cursor: pointer">
          <img
            @click="hiddeModalEditAvatar"
            width="20px"
            height="20px"
            src="@/assets/images/money/cancel.svg"
            alt="">
        </div>
        <div class="mt-3 pl-5 is-flex is-align-items-center line-bottom-gray" style="gap: 15px; cursor: pointer">
          <img
            src="https://emituscdn.com/Upload-picture-icon.svg"
            alt="">
          <p
            @click="ClickshowEditAvatar"
            class="text-basier dark-blue-emitus">
            Subir nueva foto</p>
        </div>
        <div class="mt-3 pl-5 is-flex is-align-items-center" style="gap: 15px; cursor: pointer">
          <img src="https://emituscdn.com/icon-delete-menu-blue.svg" alt="">
          <p
            @click="deleteProfilePic"
            class="text-basier dark-blue-emitus pt-1">
            Eliminar foto actual
          </p>
        </div>
      </div>
    </div>

    <!-- edit avatar -->
    <div class="modal-edit-avatar" v-if="showEditAvatar">
      <div style="text-align: end; cursor: pointer; margin: 50px 50px 0px auto;">
        <img @click="hiddeModalEditAvatar" width="30px" height="30px" src="@/assets/images/money/cancel.svg" alt="">
      </div>
      <div>
        <EditAvatar />
      </div>
    </div>

    <!----------------------------------
    B) STATUS PROFILE-------------------
    ----------------------------------->
    <div :key="keySwitch" class="container-profile-status" id="only-professional-3">
      <b-field  class="switch-avatar-general">
        <b-switch
          id="activateSessionsId"
          v-model="showp"
          class="disabledContent"
        >
          <!-- true-value="active"
          false-value="desactive" -->
            <!-- {{ showp === true ? 'Availabe' : 'Disable' }} -->
            {{ status }}
        </b-switch>
        <!-- <sup v-if="this.status !== 'Review'"><div :class="showp === true ? 'circle-available-mini' : 'circle-disable-mini'"></div></sup>
        <sup v-if="this.status === 'Review'"><div class="circle-review-mini"></div></sup> -->
      </b-field>
      <div
        v-if="missingToActivate"
        class="mt-6"
      >
        <div class="boxMissingInfo card container-validations-profile-status is-flex is-flex-direction-column mt-5">
          <span class="mb-5 white">Te falta la siguiente información para activar tu perfil:</span>
          <button class="btn-a" v-if="!calendar" @click="clickPushCalendar">Disponibilidad semanal</button>
          <button class="btn-a" v-if="durations && durations.length === 0" @click="clickPushSessions">Precio y duración de tus sesiones</button>
          <button class="btn-a" v-if="languages && languages.length === 0" @click="clickPushLanguages">Idiomas que hablas con fluidez</button>
        </div>
      </div>

      <div
        v-if="waitingForReview"
      >
        <div class="boxMissingInfo2 card container-validations-profile-status is-flex is-flex-direction-column mt-5">
          <span class="mb-5">Uno de nuestros colaboradores revisará tu [erfil antes de que se publique <br> No tardaremos mucho.</span>
        </div>
      </div>
      <div class="is-flex mt-3 is-hidden-desktop btn-copy-link-general mt-6" style="gap: 15px;">
        <a @click="showModalShareProfile" class="btn-share-profile"><span class="text-basier-medium">Compartir perfil</span></a>
        <b-button id="btn_codivy" @click="copy" class="btn-copy-profile"><span class="text-basier-medium">Copiar mi link</span></b-button>
      </div>
    </div>

    <!-- A-2) General Information -->
    <div
      class="container-content-info">
      <p
        class="title-regular title-section white">
         Información General
      </p>
      <p
        class="paragraph-content gray-ultra-light">
        Proporciona tu información personal, esta se ocultará de tu perfil público; sin embargo, la necesitaremos para notificarte.</p>
      <div
        class="container-general-info">
        <form
          class="form-general">
          <div
            class="name-user is-flex mt-5">
            <!-- Firstame -->
            <b-field label="Nombre(s)">
              <b-input
                v-on:focusin.native="FocusShowSaveAllProfile"
                v-model="firstnamep">
              </b-input>
            </b-field>

            <!-- Lastname -->
            <b-field class="ml-2" label="Apellido(s)">
              <b-input
                v-on:focusin.native="FocusShowSaveAllProfile"
                v-model="lastnamep"> </b-input>
            </b-field>
          </div>

          <b-field
            id="only-professional-4"
            class="mt-3"
            label="Link perfil ">
            <!-- username -->
            <div style="display: flex; align-items: center; gap: 15px;">
              <p class="white text-basier">https://{{ linkHost }}/</p>
              <b-input
                id="textoACopiar"
                v-on:focusin.native="FocusShowSaveAllProfile"
                v-model="usernamep"
                class=""
                style="max-width: 277px;"
                >
              </b-input>
            </div>
          </b-field>

          <!-- user profession -->
          <b-field class="mt-1" label="Ocupación">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="titlep"
              placeholder=""
              >
            </b-input>
          </b-field>

          <!-- country of residence -->
          <!-- <b-field class="mt-4" label="País de residencia">
            <b-select
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="countryoriginp"
              placeholder="Choose country"
              icon="earth"
              expanded
              >
              <option v-if="countryoriginp" disabled value="">{{countryoriginp}}</option>
              <option else disabled value="">Elige el país</option>
              <optgroup label="Most common">
                  <option value="United States">United States</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Germany">Germany</option>
                  <option value="France">France</option>
                  <option value="Italy">Italy</option>
                  <option value="Spain">Spain</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Japan">Japan</option>
                  <option value="India">India</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Poland">Poland</option>
                  <option value="Canada">Canada</option>
              </optgroup>

              <optgroup label="Other">
                <option
                  v-for="(country, idx) in countries"
                  :key="idx"
                  :value="country"
                  >
                  {{ country }}
                </option>
              </optgroup>
            </b-select>
          </b-field> -->

        </form>
      </div>
    </div>

    <!----------------------------------
    C) CONTACT INFO --------------------
    ----------------------------------->
    <div id="navSideProfile1" class="container-content-info">

    <!-- Title -->
      <p
        class="
          title-section
          text-basier
          has-text-left
          title-regular
          white
        "
      >
        Información de contacto
      </p>
      <p
        class="
        paragraph-content
        gray-ultra-light"
      >Tu información de contacto se ocultará de tu perfil. Solo lo usaremos para notificarte sobre las actualizaciones de la sesión.
      </p>

    <!-- Form -->
      <form>
        <!-- Email -->
        <b-field
          label="Email"
          :message="mensajeEmail"
          :type="messageTypeEmail">
          <b-input
            v-model="emailp"
            :disabled="!showEmail"
            placeholder="tuemail@algo.com"
            id="email"
            class="input-white"
            v-on:focusin.native="clearValidityEmail"
          > </b-input>
        </b-field>

        <!-- Phone -->
        <b-field
        label="Teléfono móvil"
        :message="mensajePhone"
        :type="messageTypePhone"
        class="mt-3"
        >
          <vue-tel-input
            v-model="phonep"
            v-bind="bindProps"
            id="phone"
            autocomplete="off"
            v-on:focusin.native="clearValidityPhone"
          >
          </vue-tel-input>
        </b-field>

      </form>

    </div>

    <!----------------------------------
    D) About me --------------------
    ----------------------------------->
    <div
      id="navSideProfile2"
      class="pb-6">
      <div
        class="container-content-about">
        <!-- component title -->
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
          Presentación
        </p>
        <p
          class="paragraph-content gray-ultra-light">
          Preséntate a clientes potenciales. Explica qué te convierte en el abogado perfecto para resolver sus dudas.
        </p>

        <!-- enter video
        <div
          class="section-video-presentation">
          <div
            class="container-subtitle">
            <svg
              width="12"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33984 1.69971H11.3398"
                stroke="#0D1239"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M1.33984 6.69971H16.3398"
                stroke="#0D1239"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <span
              class="subtitle-basier-regular dark-blue-emitus ml-4"
              >
              Intro Video
            </span>
          </div>
          <p
            class="mt-3 paragraph-content dark-gray-emitus">
            It is a long established fact that a reader will be distracted by the readable content.
          </p>
          <form>
            <//video url to enter
            <b-field
              label="Video URL">
              <b-input
                v-on:focusin.native="updateProfileGeneral"
                v-model="profilevideop"
                placeholder="Url video here...">
              </b-input>
            </b-field>
          </form>
        </div> -->

        <!-- Description -->
        <div
          class="section-text-description">
          <!-- <div
            class="container-subtitle">
            <svg
              width="12"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33984 1.69971H11.3398"
                stroke="#0D1239"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M1.33984 6.69971H16.3398"
                stroke="#0D1239"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <span
              class="subtitle-basier-regular dark-blue-emitus ml-4"
              >
              Intro Description
            </span>
          </div>
          <p
            class="mt-3 paragraph-content dark-gray-emitus">
            It is a long established fact that a reader will be distracted by the readable content.
          </p> -->
          <form>
            <!-- Description input -->
            <b-field label="Descripción" :type="textValid ? 'is-danger' : ''">
              <b-input
                v-on:focusin.native="FocusShowSaveAllProfile"
                class="text-basier white"
                v-model="descriptionp"
                @input="validText"
                type="textarea"> </b-input>
            </b-field>
          </form>
        </div>
      </div>
    </div>

    <!----------------------------------
    E) Languages --------------------
    ----------------------------------->
    <div id="navSideProfile3" class="pb-6">
      <div class="container-content-lang">
        <!-- component title -->
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
          Idiomas
        </p>
        <p class="paragraph-content gray-ultra-light">
          Agrega solo idiomas en los que puedas tener conversaciones fluidas. Es importante dar una buena experiencia en cada sesión.
        </p>

        <!-- Form -->
        <form>
          <!-- Languages input -->
          <b-field
            label="Idiomas que hablo con fluidez"
            :type="validationLanguage ? 'is-danger' : ''">
              <!-- v-on:focusin.native="validationLanguage = true" -->
            <b-taginput
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="languagesp"
              :data="filteredTags"
              :open-on-focus="openOnFocus"
              autocomplete
              :allow-new="allowNew"
              field="profile.languages"
              icon="label"
              placeholder="Añade un idioma"
              style="border: none !important;"
              closable
              @typing="getFilteredTags"
            >
                <template v-slot="filteredTags">
                  {{filteredTags.option}}
                </template>
                <template #empty>
                  <p style="color: red;">Este lenguage no existe</p>
                </template>
              <!-- :before-adding="beforeAdding" -->
            </b-taginput>
          </b-field>
          <p v-if="validationLanguage" style="color: red;" class="mt-2 text-nunito">
            Selecciona un idioma
          </p>
        </form>
      </div>
    </div>

    <!----------------------------------
    F) Skills --------------------
    ----------------------------------->
    <div id="navSideProfile4" class="pb-6">
      <div class="container-content-skill">
        <!-- title -->
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
          Especialidades
        </p>
        <p class="paragraph-content gray-ultra-light">
          Escribe las áreas del derecho en las te haz enfocado. Así ayudas a tus clientes a elegirte.
        </p>
        <form>
          <!-- skills user -->
          <b-field label="Mis especialidades">
            <b-taginput
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="skillsp"
              icon="label"
              placeholder="Civil, penal ..."
              closable
              >
            </b-taginput>
          </b-field>
        </form>
      </div>
    </div>

    <!----------------------------------
    G) Experience --------------------
    ----------------------------------->
    <div id="navSideProfile5" class="pb-6">
      <div class="container-content-education">
        <!-- component title -->
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
          Experiencia
        </p>
        <p class="paragraph-content gray-ultra-light">
          Seguro que haz participado en muchos procesos pero no hace falta hacer una hoja de vida, solo cuéntales lo más relevante.
        </p>
        <form>
          <!-- user experience -->
          <b-field label="Escribe en pocas líneas tu experiencia como abogado." :type="textValidExperience ? 'is-danger' : ''">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="experiencep"
              @input="validTextExperience"
              type="textarea"> </b-input>
          </b-field>
        </form>
      </div>
    </div>

    <!----------------------------------
    H) Education --------------------
    ----------------------------------->
    <div id="navSideProfile6" class="pb-6">
      <div class="container-content-education">
        <!-- component title -->
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
        Estudios
        </p>
        <p class="paragraph-content gray-ultra-light">
          Universidad, Master, Diplomados, todo lo que sea relevante para tus clientes. 
        </p>
        <form>
          <!-- user education -->
          <b-field label="Escribe los más relevantes" :type="textValidEducation ? 'is-danger' : ''">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              v-model="educationp"
              @input="validTextEducation"
              type="textarea"> </b-input>
          </b-field>
              <!-- minlength="10"
              maxlength="200" -->
          <!-- validation-message="" -->
        </form>
      </div>
    </div>

    <!----------------------------------
    I) Social --------------------
    ----------------------------------->
    <div id="navSideProfile7" class="bottom">
      <div class="container-content-social is-flex-direction-column">
        <p
          class="
            title-section
            text-basier
            has-text-left
            title-regular
            white
          "
        >
          Redes Sociales
        </p>
        <p class="paragraph-content gray-ultra-light">
          Añade el enlace de las redes sociales que quieres que aparezcan en tu perfil.
        </p>
        <div class="mt-6 is-flex is-align-items-center">
          <!--- Init svg icon linkedin --->
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2004 5.98291C12.4734 5.98291 13.6943 6.45564 14.5945 7.29711C15.4947 8.13858 16.0004 9.27985 16.0004 10.4699V15.7046H12.8004V10.4699C12.8004 10.0732 12.6318 9.69277 12.3318 9.41228C12.0317 9.13179 11.6247 8.97421 11.2004 8.97421C10.776 8.97421 10.3691 9.13179 10.069 9.41228C9.76896 9.69277 9.60039 10.0732 9.60039 10.4699V15.7046H6.40039V10.4699C6.40039 9.27985 6.9061 8.13858 7.80628 7.29711C8.70645 6.45564 9.92735 5.98291 11.2004 5.98291Z"
              fill="#C4C4C4"
            />
            <path d="M3.2 6.73071H0V15.7046H3.2V6.73071Z" fill="#C4C4C4" />
            <path
              d="M1.6 2.9913C2.48366 2.9913 3.2 2.32168 3.2 1.49565C3.2 0.669626 2.48366 0 1.6 0C0.716344 0 0 0.669626 0 1.49565C0 2.32168 0.716344 2.9913 1.6 2.9913Z"
              fill="#C4C4C4"
            />
          </svg>

          <!-- Fin svg icon linkedin -->

          <b-field class="ml-4">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              class="input-redes"
              v-model="linkedinp"
              placeholder="url..."> </b-input>
          </b-field>
        </div>
        <div class="mt-5 is-flex is-align-items-center">
          <!--- init svg icon facebook -->
          <svg
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0H6.54545C5.46048 0 4.41994 0.395088 3.65275 1.09835C2.88555 1.80161 2.45455 2.75544 2.45455 3.75V6H0V9H2.45455V15H5.72727V9H8.18182L9 6H5.72727V3.75C5.72727 3.55109 5.81347 3.36032 5.96691 3.21967C6.12035 3.07902 6.32846 3 6.54545 3H9V0Z"
              fill="#C4C4C4"
            />
          </svg>
          <!-- Fin svg icon facebook-->

          <b-field class="ml-5">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              class="input-redes"
              v-model="facebookp"
              placeholder="url..."> </b-input>
          </b-field>
        </div>
        <div class="mt-5 is-flex is-align-items-center">
          <!-- Init svg icon instagram-->
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0ZM8.38681 5.59139C7.88746 5.51735 7.37748 5.60264 6.9294 5.83514C6.48132 6.06764 6.11796 6.4355 5.89101 6.88642C5.66405 7.33733 5.58505 7.84832 5.66525 8.34672C5.74545 8.84512 5.98076 9.30554 6.33771 9.66249C6.69467 10.0194 7.15508 10.2548 7.65348 10.335C8.15188 10.4152 8.66287 10.3362 9.11378 10.1092C9.5647 9.88224 9.93257 9.51888 10.1651 9.0708C10.3976 8.62272 10.4829 8.11274 10.4088 7.61339C10.3333 7.10404 10.0959 6.63248 9.73183 6.26838C9.36772 5.90427 8.89616 5.66692 8.38681 5.59139ZM6.19249 4.41494C6.93929 4.02744 7.78926 3.88529 8.6215 4.0087C9.47043 4.13458 10.2564 4.53016 10.8632 5.13701C11.47 5.74385 11.8656 6.52978 11.9915 7.3787C12.1149 8.21095 11.9728 9.06091 11.5853 9.80771C11.1978 10.5545 10.5847 11.1601 9.83313 11.5384C9.08161 11.9166 8.22995 12.0483 7.39929 11.9146C6.56863 11.781 5.80126 11.3888 5.20634 10.7939C4.61142 10.1989 4.21923 9.43157 4.08557 8.60091C3.95191 7.77025 4.08357 6.91859 4.46183 6.16707C4.84009 5.41555 5.44569 4.80244 6.19249 4.41494ZM12.4 2.8C11.9582 2.8 11.6 3.15817 11.6 3.6C11.6 4.04183 11.9582 4.4 12.4 4.4H12.408C12.8498 4.4 13.208 4.04183 13.208 3.6C13.208 3.15817 12.8498 2.8 12.408 2.8H12.4Z"
              fill="#C4C4C4"
            />
          </svg>

          <!-- Fin svg icon instagram -->
          <b-field class="ml-4">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              class="input-redes"
              v-model="instagramp"
              placeholder="url..."> </b-input>
          </b-field>
        </div>
        <div class="mt-5 is-flex is-align-items-center">
          <!-- init svg icon twitter -->
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 0.00672484C15.3036 0.458456 14.5324 0.803959 13.7164 1.02993C13.2784 0.566818 12.6962 0.238578 12.0488 0.0896016C11.4013 -0.0593751 10.7196 -0.021901 10.0961 0.196956C9.47248 0.415812 8.93705 0.805492 8.56217 1.31329C8.18729 1.82109 7.99105 2.42251 8 3.0362V3.70496C6.72192 3.73544 5.45547 3.47478 4.31346 2.94622C3.17145 2.41765 2.18932 1.63758 1.45455 0.675484C1.45455 0.675484 -1.45455 6.69432 5.09091 9.36935C3.59311 10.3043 1.80884 10.773 0 10.7069C6.54545 14.0507 14.5455 10.7069 14.5455 3.01614C14.5448 2.82986 14.5253 2.64404 14.4873 2.46107C15.2295 1.78796 15.7533 0.938114 16 0.00672484Z"
              fill="#C4C4C4"
            />
          </svg>

          <!-- Fin svg icon twitter -->

          <b-field class="ml-4">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              class="input-redes"
              v-model="twitterp"
              placeholder="url..."> </b-input>
          </b-field>
        </div>
        <div class="mt-5 is-flex is-align-items-center">
          <!-- init svg icon tiktok -->
          <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3.4397 11.5675C3.4397 9.42466 5.25636 8.77886 6.34576 9.24854V6.78278C1.90741 6.26614 0.934805 9.75734 1.0033 11.5675C1.19116 15.1135 4.23226 16 5.72933 16C9.1579 15.8121 10.3673 13.0793 10.3673 11.5675V5.99022C12.0346 7.11742 13.5278 7.22309 14.0659 7.13503V4.72798C11.107 4.46967 10.3673 2.13503 10.3673 1H7.87218V11.0391C7.87218 11.5675 7.75477 13.5049 5.72933 13.5342C4.10897 13.5577 3.4397 12.3014 3.4397 11.5675Z" fill="#C7C8D1" stroke="#C7C8D1" stroke-linecap="round"/>
          </svg>

          <!-- Fin svg icon tiktok -->

          <b-field class="ml-4">
            <b-input
              v-on:focusin.native="FocusShowSaveAllProfile"
              class="input-redes"
              v-model="tiktokp"
              placeholder="url..."> </b-input>
          </b-field>
        </div>
      </div>
    </div>

    <!-- SPACE -->
    <div
      class="space-down"
    >
    </div>

  <!-- SAVE -->
    <div class="save_all" :class="saveAllProfile ? '' : 'save_all_mobil_hidde'">
      <div v-if="saveAllProfile" class="is-flex is-align-items-center container-save-changes">
        <a
          @click="cancellAll"
          class="btn-cancel-profile-white text-basier"
          >Cancelar</a>
        <b-button
          @click.prevent="saveAll"
          class="button-primary-extra-mini"
          ><span class="text-basier-medium">Guardar cambios</span></b-button>
      </div>
      <!-- <div class="is-hidden-touch button-need-help">
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=+34643943764&text=Hi, I need help with"
          >
          <img
            class="mr-3"
            src="https://emituscdn.com/help-icon.svg"
            alt="icon">
          Do you need Help ?
        </a>
      </div> -->
    </div>

    <!-- modal share profile -->
    <ModalShareProfile
      v-if="showShareProfile"
      :usernamep="usernamep"
      :titlep="titlep"
    />

    <!-- LOGIN RECET MODAL -->

    <b-modal
        v-model="loginModal"
        full-screen
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="['escape']">
        <div>
          <Login
            @close="loginModal = false"
          />
        </div>

    </b-modal>
    <div v-if="loader">
      <Loading/>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import UserService from '@/services/UserService'
import ProfileService from '@/services/ProfileService'
import Media from '@/services/MediaService'
import Login from '@/components/modals/loginrecent.vue'
import MixpanelService from '@/services/MixpanelService'
// import mixpanel from 'mixpanel-browser'
import ModalShareProfile from '@/components/modals/modalShareProfile.vue'

import firebase from 'firebase/app'
import 'firebase/auth'
import { mask } from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input'

import EditAvatar from '@/components/panel/profile/editAvatar.vue'

const languagesOpt = [
  'Español',
  'Catalán',
  'Gallego',
  'Valenciano',
  'Gallego',
  'Euskera',
  'Inglés',
  'Francés',
  'Árabe',
  'Mandarin',
  'Ruso',
  'Alemán',
  'Portugués',
  'Japonés',
  'Hindi',
  'Malay',
  'Persa',
  'Swahili',
  'Tamil',
  'Italiano',
  'Holandés',
  'Bengali',
  'Turco',
  'Vietnamita',
  'Polaco',
  'Javanese',
  'Punjabi',
  'Thai',
  'Koreano']

export default {
  directives: { mask },
  components: {
    EditAvatar,
    VueTelInput,
    Login,
    ModalShareProfile,
    Loading
  },
  name: 'MyProfile',

  // DATA
  data () {
    return {
      // Main info
      user: null,
      profile: null,
      userToSend: {},
      profileToSend: {},
      authUser: null,

      // General info
      duration: null,
      calendar: null,

      avatar: null,
      username: null,
      firstname: null,
      lastname: null,
      countryorigin: null,

      avatarp: '',
      usernamep: '',
      firstnamep: '',
      lastnamep: '',
      titlep: '',
      countryoriginp: '',

      title: null,
      languages: null,

      // Contact Info
      email: null,
      phone: null,
      showEmail: null,

      phonep: '',
      emailp: '',
      mensajeEmail: '',
      mensajePhone: '',
      messageTypeEmail: '',
      messageTypePhone: '',
      valid: true,
      bindProps: {
        mode: 'international',
        preferredCountries: ['US', 'ES', 'DE', 'GB'],
        onlyCountries: [],
        ignoredCountries: []
      },

      // About me
      description: null,
      profilevideo: null,
      descriptionp: '',
      profilevideop: '',
      textValid: false,

      // Languages
      languagesp: [],
      isSelectOnly: false,
      allowNew: false,
      openOnFocus: true,
      filteredTags: languagesOpt,
      validationLanguage: false,

      // Skills
      skills: null,
      skillsp: [],

      // Experience
      experience: null,
      experiencep: '',
      textValidExperience: false,

      // Education
      education: null,
      educationp: '',
      textValidEducation: false,

      // Social
      linkedin: null,
      twitter: null,
      github: null,
      tiktok: null,
      facebook: null,
      instagram: null,
      linkedinp: '',
      twitterp: '',
      githubp: '',
      tiktokp: '',
      instagramp: '',
      facebookp: '',

      // Other
      locale: undefined, // Browser locale,
      countries: [
        'Andorra',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahrein',
        'Belgium',
        'Benin',
        'Bhutan',
        'Brazil',
        'British Indian Ocean Territory',
        'Bulgaria',
        'Chile',
        'China',
        'Canada',
        'Costa Rica',
        'Cote Ivoire',
        'Comoros',
        'Croatia',
        'Curacao',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Estonia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French Guiana',
        'Georgia',
        'Germany',
        'Gibraltar',
        'Greece',
        'Guadeloupe',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'Ireland',
        'Israel',
        'Italy',
        'Japan',
        'Jordan',
        'Kenya',
        'Korea',
        'Kiribati',
        'Latvia',
        'Lesotho',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Mayotte',
        'Martinique',
        'Malta',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Morocco',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Norway',
        'Oman',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Rwanda',
        'Saint Pierre And Miquelon',
        'San Marino',
        'Saudi Arabia',
        'Saint Barthelemy',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Solomon Islands',
        'Spain',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Taiwan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Turkey',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Vietnam',
        'Wallis And Futuna'
      ],
      showEditAvatar: false,
      showModalSelect: false,
      dropFiles: [],
      image: '',
      showp: null,
      showModalValidationProfile: null,
      keySwitch: 0,
      approved: null,
      idToken: null,
      counter: 0,
      linkHost: null,
      missingToActivate: false,

      loginModal: false,
      saveAllProfile: false,
      userToSendCount: 0,
      profileToSendCount: 0,
      loader: false,
      showShareProfile: false

    }
  },

  // WATCH
  watch: {
    // Main
    user: function () {
      this.calendar = this.user.calendar
      this.username = this.user.username
      this.durations = this.user.durations
      this.avatar = this.user.avatar
      this.username = this.user.username
      this.firstname = this.user.firstname
      this.lastname = this.user.lastname
      this.countryorigin = this.user.countryorigin
      this.phone = this.user.phone
      this.email = this.user.email
      this.linkHost = window.location.host
    },
    profile: function () {
      this.title = this.profile.title
      this.approved = this.profile.approved
      this.languages = this.profile.languages
      this.profilevideo = this.profile.profilevideo
      this.description = this.profile.description
      this.skills = this.profile.skills
      this.experience = this.profile.experience
      this.education = this.profile.education
      this.linkedin = this.profile.linkedin
      this.twitter = this.profile.twitter
      this.github = this.profile.github
      this.tiktok = this.profile.tiktok
      this.facebook = this.profile.facebook
      this.instagram = this.profile.instagram
    },

    // General Info
    linkHost: function () {
      const userN = this.linkHost.split('/')[1]
      this.usernamep = userN
    },
    avatar: function () {
      this.avatarp = this.avatar
    },
    username: function () {
      this.usernamep = this.username
    },
    firstname: function () {
      this.firstnamep = this.firstname
    },
    lastname: function () {
      this.lastnamep = this.lastname
    },
    title: function () {
      this.titlep = this.title
    },
    countryorigin: function () {
      this.countryoriginp = this.countryorigin
    },

    // Contact Info
    phone: function () {
      this.phonep = this.phone
    },
    email: function () {
      this.emailp = this.email
    },

    // About me
    description: function () {
      this.descriptionp = this.description
    },
    profilevideo: function () {
      this.profilevideop = this.profilevideo
    },

    // Languages
    languages: function () {
      this.languagesp = this.languages
    },

    // Skills
    skills: function () {
      this.skillsp = this.skills
    },

    // experience
    experience: function () {
      this.experiencep = this.experience
    },

    // education
    education: function () {
      this.educationp = this.education
    },

    // social
    linkedin: function () {
      this.linkedinp = this.linkedin
    },
    twitter: function () {
      this.twitterp = this.twitter
    },
    github: function () {
      this.githubp = this.github
    },
    tiktok: function () {
      this.tiktokp = this.tiktok
    },
    facebook: function () {
      this.facebookp = this.facebook
    },
    instagram: function () {
      this.instagramp = this.instagram
    },

    // Activate logic
    showp: function () {
      if (this.showp && this.counter > 0) {
        this.updateProfileGeneralActive({ show: true })
        this.approveProfile()
        this.notify.$emit('showpNavbar', this.showp)
      } else if (this.showp === false && this.counter > 0) {
        this.updateProfileGeneralActive({ show: false })
        this.notify.$emit('showpNavbar', this.showp)
      }
      this.counter++
    }
    // showp: function () {
    //   if (this.showp && this.counter > 0) {
    //     if (this.languages && this.durations) {
    //       if (this.languages.length > 0 && this.durations.length > 0 && this.calendar) {
    //         this.updateProfileGeneralActive({ show: true })
    //         this.notify.$emit('showpNavbar', this.showp)
    //       } else {
    //         this.showModalValidationProfile = true
    //       }
    //     }
    //   } else if (this.showp === false && this.counter > 0) {
    //     this.updateProfileGeneralActive({ show: false })
    //     this.notify.$emit('showpNavbar', this.showp)
    //   }
    //   this.counter++
    // }

  },

  // CREATED
  async created () {
    this.loader = true
    // Get AuthUser
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
    })

    // hidde modal share profile
    this.notify.$on('hiddeShareSocial', () => {
      this.showShareProfile = false
    })

    // Get User + Profile
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const userResponse = await UserService.getCurrentUser(idToken)
    const profileResponse = await ProfileService.getCurrentProfile(idToken)
    this.user = userResponse.data
    this.durations = this.user.durations
    this.calendar = this.user.calendar
    this.profile = profileResponse.data
    this.languages = this.profile.languages
    this.approved = this.profile.approved
    this.handleActivateSessions()
    // Hide modal Avatar
    this.notify.$on('hiddeModalAvatarEdit', () => {
      this.hiddeModalEditAvatar()
    })

    // Avatar change
    this.notify.$on('refreshAvatar', url => {
      this.avatarp = url
    })
    setTimeout(() => {
      this.loader = false
    }, 2000)
    // Save info
    // this.notify.$on('saveAll', () => {
    //   if (
    //     this.firstnamep !== this.firstname ||
    //     this.lastnamep !== this.lastname ||
    //     this.usernamep !== this.username ||
    //     this.countryoriginp !== this.countryorigin ||
    //     this.avatarp !== this.avatar) {
    //     this.updateUserGeneral()
    //   }
    //   if (
    //     this.titlep !== this.title ||
    //     this.lastnamep !== this.lastname ||
    //     this.usernamep !== this.username) {
    //     this.updateProfileGeneral()
    //   }
    // })
  },

  // MOUNTED
  mounted () {
    // Check if something is missing to activate profile
    // setTimeout(() => {
    //   if (this.languages && this.durations) {
    //     console.log('algo 1')
    //     if (this.languages.length > 0 && this.durations.length > 0 && this.calendar) {
    //       console.log('algo 2')
    //       this.missingToActivate = false
    //       document.getElementById('activateSessionsId').classList.remove('disabledContent')
    //       this.showp = this.profile.show
    //     } else {
    //       console.log('algo 3')
    //       this.missingToActivate = true
    //       document.getElementById('activateSessionsId').classList.add('disabledContent')
    //       this.showp = false
    //     }
    //   } else {
    //     console.log('algo 4')
    //     this.missingToActivate = true
    //     document.getElementById('activateSessionsId').classList.add('disabledContent')
    //     this.showp = false
    //   }
    // }, 1000)

    // Show email or not
    if (this.authUser && this.authUser.email) {
      const emailAuth = this.authUser.email
      const domainEmail = emailAuth.substring(emailAuth.indexOf('@') + 1)
      if (this.authUser.providerData[0].providerId === 'password' ||
          domainEmail === 'privaterelay.appleid.com') {
        this.showEmail = true
      }
    } else {
      this.showEmail = true
    }
  },

  // COMPUTED
  computed: {
    status: function () {
      if (this.showp && this.approved) {
        return 'Profile Available'
      } else if (this.showp && !this.approved) {
        return 'Review'
      } else {
        return 'Offline'
      }
    },

    waitingForReview: function () {
      if (this.status === 'Review') {
        return true
      } else {
        return false
      }
    }
  },

  // METHODS
  methods: {
    showModalShareProfile () {
      this.showShareProfile = true
    },
    FocusShowSaveAllProfile () {
      this.saveAllProfile = true
    },
    handleActivateSessions () {
      if (this.languages && this.durations) {
        if (this.languages.length > 0 && this.durations.length > 0 && this.calendar) {
          this.missingToActivate = false
          document.getElementById('activateSessionsId').classList.remove('disabledContent')
          this.showp = this.profile.show
        } else {
          this.missingToActivate = true
          document.getElementById('activateSessionsId').classList.add('disabledContent')
          this.showp = false
        }
      } else {
        this.missingToActivate = true
        document.getElementById('activateSessionsId').classList.add('disabledContent')
        this.showp = false
      }
    },
    // Notify discord profile approval request
    async approveProfile () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await ProfileService.approveProfile(idToken)
      MixpanelService.showProfileMixpanel(this.user._id)
      if (response.status === 200) {
        this.notification('Solicitud de aprobación enviado')
      } else {
        this.danger('Hubo un error con la solicitud de aprobación')
      }
    },

    // Get Current User & Profile
    async getUserAndProfile () {
      if (this.authUser) {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            await UserService.getCurrentUser(idToken)
              .then(user => {
                this.user = user.data
              })
            await ProfileService.getCurrentProfile(idToken)
              .then(resp => {
                this.profile = resp.data
              })
          })
      }
    },
    updateProfileGeneralActive (show) {
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          try {
            await ProfileService.updateCurrentProfile(idToken, show)
            this.notification('Guardado')
          } catch (error) {
            console.log('error updating profile', error)
          }
        })
    },

    // Save All
    async saveAll () {
      this.loader = true
      if (this.firstnamep !== this.firstname) {
        this.userToSend.firstname = this.firstnamep
        this.userToSendCount++
      }
      if (this.lastnamep !== this.lastname) {
        this.userToSend.lastname = this.lastnamep
        this.userToSendCount++
      }
      if (this.usernamep !== this.username) {
        this.userToSend.username = this.usernamep
        this.userToSendCount++
      }
      if (this.countryoriginp !== this.countryorigin) {
        this.userToSend.countryorigin = this.countryoriginp
        this.userToSendCount++
      }
      let emailSaved = false
      if (this.phonep !== this.phone || this.emailp !== this.email) {
        // Contact info
        this.valid = true
        this.ValidityEmail()
        this.validPhone()

        if (this.valid) {
          const response = await this.updateContactInfo({
            email: this.emailp,
            phone: this.phonep
          })
          if (response === false) {
            this.loader = false
            return false
          } else {
            emailSaved = true
          }
        } else {
          this.danger('Hay un problema con el correo o con el teléfono')
          this.loader = false
          return false
        }
      }

      if (this.titlep !== this.title) {
        this.profileToSend.title = this.titlep
        this.profileToSendCount++
      }
      if (this.descriptionp !== this.description) {
        // this.validText(this.descriptionp)
        if (this.textValid === false) {
          this.profileToSend.description = this.descriptionp
          this.profileToSendCount++
        } else {
          this.danger('Hay un problema con la descripción')
          this.loader = false
          return false
        }
      }
      if (this.profilevideop !== this.profilevideo) {
        this.profileToSend.profilevideo = this.profilevideop
        this.profileToSendCount++
      }
      if (this.languagesp !== this.languages) {
        if (this.languagesp.length > 0 && this.validationLanguage === false) {
          this.profileToSend.languages = this.languagesp
          this.profileToSendCount++
        } else {
          this.validationLanguage = true
        }
      }
      if (this.skillsp !== this.skills) {
        this.profileToSend.skills = this.skillsp
        this.profileToSendCount++
      }
      if (this.experiencep !== this.experience) {
        if (this.textValidExperience === false) {
          this.profileToSend.experience = this.experiencep
          this.profileToSendCount++
        } else {
          this.danger('Hubo un problema con la Experiencia')
          this.loader = false
          return false
        }
      }
      if (this.educationp !== this.education) {
        if (this.textValidEducation === false) {
          this.profileToSend.education = this.educationp
          this.profileToSendCount++
        } else {
          this.danger('Hubo un problema con la Educación')
          this.loader = false
          return false
        }
      }
      if (this.linkedinp !== this.linkedin) {
        this.profileToSend.linkedin = this.linkedinp
        this.profileToSendCount++
      }
      if (this.twitterp !== this.twitter) {
        this.profileToSend.twitter = this.twitterp
        this.profileToSendCount++
      }
      if (this.githubp !== this.github) {
        this.profileToSend.github = this.githubp
        this.profileToSendCount++
      }
      if (this.tiktokp !== this.tiktok) {
        this.profileToSend.tiktok = this.tiktokp
        this.profileToSendCount++
      }
      if (this.facebookp !== this.facebook) {
        this.profileToSend.facebook = this.facebookp
        this.profileToSendCount++
      }
      if (this.instagramp !== this.instagram) {
        this.profileToSend.instagram = this.instagramp
        this.profileToSendCount++
      }

      console.log('111', this.profileToSend)

      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        let responseUser = null
        let responseProfile = null
        if (this.profileToSendCount > 0 && this.userToSendCount > 0) {
          console.log('AAA Both')
          responseUser = await UserService.updateUser(idToken, this.userToSend)
          responseProfile = await ProfileService.updateCurrentProfile(idToken, this.profileToSend)
          if ((responseUser && responseUser.statusText === 'OK' && responseProfile && responseProfile.statusText === 'Created') || emailSaved) {
            this.notification('Guardado')
            this.notify.$emit('refreshMyProfile')
            this.loader = false
          }
        } else if (this.profileToSendCount > 0 && this.userToSendCount === 0) {
          console.log('BBB Only Profile')
          responseProfile = await ProfileService.updateCurrentProfile(idToken, this.profileToSend)
          if ((responseProfile && responseProfile.statusText === 'Created') || emailSaved) {
            this.notification('Guardado')
            this.notify.$emit('refreshMyProfile')
            this.loader = false
          }
        } else if (this.profileToSendCount === 0 && this.userToSendCount > 0) {
          console.log('CCC Only User')
          responseUser = await UserService.updateUser(idToken, this.userToSend)
          if ((responseUser && responseUser.statusText === 'OK') || emailSaved) {
            this.notification('Guardado')
            this.notify.$emit('refreshMyProfile')
            this.loader = false
          }
        } else if (this.profileToSendCount === 0 && this.userToSendCount === 0) {
          console.log('DDD Neither')
          this.loader = false
        } else {
          console.log('EEE Mistake')
        }

        // if (this.userToSendCount > 0) {
        //   responseUser = await UserService.updateUser(idToken, this.userToSend)
        // }
        // const responseProfile = await ProfileService.updateCurrentProfile(idToken, this.profileToSend)
        // if ( this.userToSendCount > 0) )
        // if ((responseUser && responseUser.statusText === 'OK' && responseProfile.statusText === 'Created') || emailSaved) {
        //   this.notification('Saved')
        //   this.notify.$emit('refreshMyProfile')
        //   this.loader = false
        // }
      } catch (error) {
        console.log('error updating user', error)
        this.danger('Algo salió mal')
        this.loader = false
      }
    },

    // Cancell All
    async cancellAll () {
      this.notify.$emit('refreshMyProfile')
      window.scroll(0, 0)
    },

    // Update Contact General
    async updateContactInfo (contactInfo) {
      const email = contactInfo.email
      const user = firebase.auth().currentUser
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      if (!this.authUser.email) { // Facebook without email
        await UserService.updateUser(idToken, contactInfo)
        return true
        // this.notification('Saved')
        // this.notification()
        // this.notify.$emit('refreshMyProfile')
      } else {
        const emailAuth = this.authUser.email
        const domainEmail = emailAuth.substring(emailAuth.indexOf('@') + 1)

        if (this.authUser.providerData[0].providerId === 'password') { // Password
          try {
            await user.updateEmail(email)
            // alert('Contact information changed successfully in Firebase')

            await UserService.updateUser(idToken, contactInfo)
            return true
            // this.notification('Saved')
            // this.notification()
            // this.notify.$emit('updateData')
          } catch (error) {
            if (error.code === 'auth/requires-recent-login') {
              this.loginModal = true
              return false
            } else {
              console.log('error updating', error)
              return false
            }
          }
        } else if (domainEmail === 'privaterelay.appleid.com') { // Apple with private email
          await UserService.updateUser(idToken, contactInfo)
          return true
          // this.notification()
          // this.notify.$emit('updateData')
        } else { // Google & Apple with valid email & Facebook with valid email
          await UserService.updateUser(idToken,
            {
              phone: contactInfo.phone
            })
          return true
          // this.notification()
          // this.notify.$emit('updateData')
        }
      }
    },

    // Validation: Description
    validText (text) {
      if (text.length === 0 || (text.length >= 10 && text.length <= 300)) {
        this.textValid = false
      } else {
        this.textValid = true
      }
    },

    // Validation: Experience
    validTextExperience (text) {
      if (text.length === 0 || (text.length >= 10 && text.length <= 400)) {
        this.textValidExperience = false
      } else {
        this.textValidExperience = true
      }
    },

    // Validation: Education
    validTextEducation (text) {
      if (text.length === 0 || (text.length >= 10 && text.length <= 400)) {
        this.textValidEducation = false
      } else {
        this.textValidEducation = true
      }
    },

    // Validation: Lanuages
    getFilteredTags (text) {
      this.validationLanguage = false
      this.filteredTags = languagesOpt.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
      if (text.length > 2) {
        this.validationLanguage = true
      } else {
        this.validationLanguage = false
      }
    },

    // Copy link
    copy () {
      this.notificationCopy()
      const link2 = `${this.linkHost}/${this.usernamep}`
      const input = document.body.appendChild(document.createElement('input'))
      input.value = link2
      input.focus()
      input.select()
      document.execCommand('copy')
      input.parentNode.removeChild(input)
      // mixpanel.track('Copy my Link', {
      //   link: `${this.linkHost}/${this.usernamep}`
      // })
      // mixpanel.people.set({
      //   $name: this.user.firstname + ' ' + this.user.lastname,
      //   $email: this.user.email
      // })
    },

    // Modals
    ClickShowModalSelect () {
      this.showModalSelect = true
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    ClickshowEditAvatar () {
      this.showEditAvatar = true
      this.showModalSelect = false
      const publicProfile = document.body
      publicProfile.classList.add('noScroll')
    },
    hiddeModalEditAvatar () {
      this.showModalSelect = false
      this.showEditAvatar = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },

    // Missing info to activate
    clickPushCalendar () {
      document.getElementById('sessions_id').click()
      this.notify.$emit('changeSection', 'sessions')
      window.scroll(0, 0)
    },
    clickPushSessions () {
      document.getElementById('sessions_id').click()
      this.notify.$emit('changeSection', 'sessions')
      window.scroll(0, 250)
    },
    clickPushLanguages () {
      window.scroll(0, 1760)
    },

    // Delete profile picture
    async deleteProfilePic () {
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      // Delete old images from S3
      const deletedResponse = await Media.deleteImageS3(idToken)
        .catch(err => {
          console.log('----> Error deleteImageS3', err)
          this.danger('Algo salió mal')
        })

      // Save url to DB: user.avatar
      const user = await UserService.updateUser(idToken,
        {
          avatar: 'https://emituscdn.com/avatar.svg',
          avatarCloudinaryId: '',
          avatarS3Key: ''
        })
        .then(response => {
          this.notify.$emit('refreshAvatar', response.data.avatar)
          this.notification('Perfil eliminado')
        })
        .catch(err => {
          console.log('----> Error updateUser', err)
          this.danger('Algo salió mal')
        })
      // console.log('----> avatar', user)

      // Update avatar in App
      this.notify.$emit('updateData')
      this.fileCancel()
    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    },
    notificationCopy () {
      this.$buefy.toast.open({
        duration: 500,
        message: 'Copied!',
        position: 'is-top-right',
        type: 'is-success'
      })
    },

    // file cancell
    async fileCancel () {
      this.dropFiles = []
      this.image = ''
      this.showModalSelect = false
    },

    // Contact Info
    ValidityEmail () {
      if (!this.emailp) {
        this.mensajeEmail = 'We\'ll send booking notifications to this email.'
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      } else if (!this.validEmail(this.emailp)) {
        this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      } else {
        this.mensajeEmail = ''
        this.messageTypeEmail = ''
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validPhone () {
      if (!this.phonep) {
        this.mensajePhone = 'We\'ll send booking notifications to this phone number.'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        this.valid = false
      } else {
        console.log('oiuy2')
        this.mensajePhone = ''
        this.messageType = ''
      }
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
      this.FocusShowSaveAllProfile()
    },
    clearValidityPhone () {
      this.mensajePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
      this.FocusShowSaveAllProfile()
    }
  },
  beforeDestroy () {
    this.notify.$off('updateUser')
    this.notify.$off('updateProfile')
  }
}
</script>

<style scoped>
@import "../../../../node_modules/vue-tel-input/dist/vue-tel-input.css";
.link-profesional-info {
  color: #7c7c7c;
  text-decoration: underline;
}
#activateSessionsId {
  justify-content: center!important;
}
.bottom-separation {
  width: 9%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.21);
}
.btn-share-profile {
  height: 32px;
  width: 114px;
  background: #0dc366;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-share-profile span {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
}
.btn-copy-profile {
  height: 34px;
  width: 106px;
  background: rgba(162, 255, 207, 0.2);
  border-radius: 0px;
  border: none;
  display: flex;
  align-items: flex-end;

}
.btn-copy-profile span {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #75bf99;
}
.btn-cancel-profile-white {
  border: none;
  color: #DCDCDC;
  text-decoration: underline;
}

.container-content-dash {
  width: 97%;
  margin: auto;
  margin-top: 69px;
  max-width: 460px;
  padding-bottom: 23px;
}

.input-redes {
  width: 270px;
}

.container-content-education {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
}

.container-content-education {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
}

.container-content-skill {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
}

.container-content-lang {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
}

.container-content-about {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
}

.container-content-social {
  width: 85%;
  margin: auto;
  margin-top: 85px;
  max-width: 460px;
  margin-bottom: 205px;
}

.section-text-description {
  margin-top: 70px;
}

.section-video-presentation {
  margin-top: 70px;
}

.space-down {
  height: 189px;
}

.save_all {
  width: 100%;
  height: 90px;
  display: flex;
  text-align: center;
  justify-content: center;
  bottom: 0;
  position: fixed;
  /*background-color: rgba(4,8,39,1);*/
  background: linear-gradient(360deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
  z-index: 11;
}

.button-need-help {
  position: absolute;
  right: 60px;
  top: 40px;
}

.button-need-help a {
  display: flex;
  font-size: 13px;
}
.container-content-info {
  width: 85%;
  margin: auto;
  margin-top: 54px;
  max-width: 460px;
}

form {
  max-width: 303px;
  margin-top: 70px;
}

.form-general {
  margin-top: 0px !important;
}
.vue-tel-input {
  border:1px solid #ECECEC !important;
}

.button-primary-mini {
  border-radius: 18px;
}

.disabledContent {
    pointer-events: none;
    opacity: 0.4;
}
/*
.btn-a {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}*/

.boxMissingInfo {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.boxMissingInfo2 {
  background-color: #e5ffff;
  border-radius: 10px;
  padding: 20px;
}

.modal-validations-profile-status {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.container-profile-status {
  width: 85%;
  margin: auto;
  margin-top: 10px;
  max-width: 460px;
}

.container-profile-status .switch {
  flex-direction: row-reverse;
  width: 100%;
  gap: 10px;
}
.container-profile-status .field.has-addons,
.container-profile-status .field-body {
  width: 250px!important;
}
.container-validations-profile-status span {
  font-family: "Nunito";
  line-height: 22px;
  font-size: 14px;
}
.container-validations-profile-status button {
  border: none;
  background: transparent;
  text-align: inherit;
  font-family: "Nunito";
  padding: 0px;
  text-decoration: underline;
  color: #56A9B9;
  margin: 8px 0px;
  cursor: pointer;
}
.circle-available {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  position: absolute;
  top: 12px;
  right: 16px;
  border: 2px solid;
}
.circle-review {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 16px;
  border: 2px solid;
  background: #ff9100;
}
.circle-disable {
  width: 23px;
  height: 23px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  position: absolute;
  top: 12px;
  right: 16px;
  border: 1.5px solid #0B0C33;
}
.circle-available-mini {
  width: 7px;
  height: 7px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #00D6CB;
  margin-left: 4px;
  top: 12px;
  right: 16px;
}
.circle-review-mini {
  width: 12px;
  height: 12px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ff9100;
  top: 12px;
  right: 16px;
}
.circle-disable-mini {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #FF1515;
  top: 12px;
  right: 16px;
}

.bottom-edit-photo {
  /* margin-top: -43px; */
  top: 0;
  position: absolute;
  color:#fff;
  cursor: pointer;
  width: 131px;
  height: 131px;
  border-radius: 131px;
  background:  rgba(13,18,57,0.3);
  z-index: 3;
  /*background-color: linear-gradient(90deg, rgba(13,18,57,1) 100%, rgba(13,18,57,1) 0%);*/
}

.content-bottom-edit-photo {
  height: 117px;
  justify-content: flex-end;
  flex-direction: column;
}

.icon-text-edit {
  height: 15px;
  justify-content: center;
  align-items: center;
}

.container-select-edit-avatar {
  background: #fff;
  z-index: 38;
  width: 80%;
  max-width: 332px;
  height: 261px;
  border-radius: 5px;
  padding: 25px;
  gap: 24px;
}

.line-bottom-gray {
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 35px;
}

.bg-modal-select-edit-avatar {
  background-color: rgba(13, 18, 57, 0.87);
  width: 100%;
  height: 100%;
  position: fixed;
}
.modal-select-edit-avatar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 33;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-edit-avatar {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 33;
  overflow: auto;
}
.link-profile-user a {
  margin-top: 15px;
  font-family: "Basier Circle";
  text-decoration: underline;
}

.container-general-info {
  width: 99%;
  margin: auto;
  margin-top: 50px;
  max-width: 342px;
}

.picwrap {
  margin-top: 16px;
  width: 131px;
  height: 131px;
}

.icon-link {
  height: 100%;
}

.button-link {
  border: none;
  background: #f8f8f8;
  border-radius: 15px;
}
#textoACopiar {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.copy-button {
  background-color: #EEEEEE !important;
  border: none !important;
  width: 81px !important;
}
.copy-button:active {
  background-color: #6E3DEA !important;
  border: none !important;
  color: #fff!important;
}

@media (max-width: 400px) {
  .copy-button {
    height: 45px !important;
    width: 72.5px !important;
  }
}

@media (min-width: 959px) {
  .copy-button {
    height: 45px !important;
    width: 73px !important;
  }
  .container-content-dash {
    display: flex;
    align-items: center;
    max-width: 590px;
    margin: auto;
  }

  .bottom-edit-photo {
    width: 178px;
    height: 178px;
  }

  .content-bottom-edit-photo {
    height: 147px;
  }
  .container-picture {
    margin: auto;
    margin-top: 109px;
  }

  .container-general-info {
    margin-left: 0px;
  }

  .container-content-info {
    margin-top: 100px;
  }

  .picwrap {
    width: 178px;
    height: 178px;
  }

  .input, .textarea, .taginput .taginput-container.is-focusable, .select select {
    border:1px solid #EEEEEE !important;
  }
}
@media (max-width: 1024px) {
  .title-section {
    max-width: 179px;
  }
  .name-profesional {
    font-family: "Basier Circle Medium";
    font-size: 18px;
  }
  .is-flex-direction-styles-responsive {
    flex-direction: column;
  }
  .switch-avatar-general {
    display: flex;
    justify-content: center;
  }
  .btn-copy-link-general {
    justify-content: center;
  }
  .container-save-changes {
    gap: 120px;
  }
  .save_all_mobil_hidde {
    display: none;
  }

  .circle-disable {
    width: 17px;
    height: 17px;
    top: 6px;
    right: 12px;
  }

  .circle-review {
    width: 17px;
    height: 17px;
    top: 6px;
    right: 12px;
  }

  .circle-available {
    width: 17px;
    height: 17px;
    top: 6px;
    right: 12px;
  }
}

@media (min-width: 1024px) {

  .name-profesional {
    font-family: "Basier Circle Bold";
    font-size: 30px;
  }
  .container-picture {
    margin-left: 61px;
    margin-top: 0px;
  }
  #activateSessionsId {
    justify-content: flex-start!important;
    padding-left: 10px;
  }
  .container-profile-status .switch {
    flex-direction: row;
  }
  .is-flex-direction-styles-responsive {
    flex-direction: row;
  }
  .space-down {
    height: 529px;
  }
  .container-save-changes {
    gap: 270px;
  }
  .bottom-edit-photo {
    width: 178px;
    height: 178px;
    border-radius: 71px;
  }

  .content-bottom-edit-photo {
    height: 146px;
  }

  .container-content-dash {
    margin-top: 131px;
  }
}
</style>
