<template>
  <div class="modal-share-profile">
    <div @click="hiddeShareModal" class="bg-modal-share-profile"></div>
    <div class="container-modal-share-profile is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      <div class="cancel-modal-share-profile">
        <svg @click="hiddeShareModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 1L1 19" stroke="#0D1239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 1L19 19" stroke="#0D1239" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <p class="text-basier title-share-profile-modal">Share your profile</p>
      <div class="is-flex" style="gap: 20px; flex-wrap: wrap; max-width: 260px;">
        <a target="_blank">
          <svg width="25" height="28" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5541 20.726C5.5541 16.726 8.94521 15.5205 10.9788 16.3973V11.7945C2.69383 10.8301 0.878302 17.347 1.00616 20.726C1.35684 27.3452 7.03355 29 9.82807 29C16.2281 28.6493 18.4856 23.5479 18.4856 20.726V10.3151C21.5979 12.4192 24.3852 12.6164 25.3897 12.4521V7.9589C19.8664 7.47671 18.4856 3.11872 18.4856 1H13.8281V19.7397C13.8281 20.726 13.6089 24.3425 9.82807 24.3973C6.80342 24.4411 5.5541 22.0959 5.5541 20.726Z" fill="white" stroke="white" stroke-linecap="round"/>
          </svg>
        </a>
        <a :href="'https://twitter.com/intent/tweet?text=Entra%20a%20mi%20Perfil&url=https%3A%2F%2Femitus.com%2F' + usernamep + '&via=emitus&hashtags=' + titlep" target="_blank">
          <svg width="29" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3333 0.0123289C28.0565 0.840504 26.6428 1.47392 25.1467 1.8882C24.3437 1.03917 23.2764 0.437393 22.0894 0.16427C20.9023 -0.108854 19.6527 -0.0401518 18.5094 0.361085C17.3662 0.762322 16.3846 1.47673 15.6973 2.4077C15.01 3.33866 14.6503 4.44126 14.6667 5.56637V6.79243C12.3235 6.8483 10.0017 6.37044 7.90801 5.4014C5.81433 4.43237 4.01376 3.00224 2.66667 1.23839C2.66667 1.23839 -2.66667 12.2729 9.33333 17.1771C6.58737 18.8911 3.31621 19.7506 0 19.6293C12 25.7596 26.6667 19.6293 26.6667 5.52959C26.6654 5.18808 26.6297 4.84741 26.56 4.51196C27.9208 3.27793 28.8811 1.71988 29.3333 0.0123289Z" fill="white"/>
          </svg>
        </a>
        <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=https://emitus.com/usernamep' + '/' + usernamep" target="_blank">
          <svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3252 10.79C21.4083 10.79 23.4062 11.5636 24.8792 12.9405C26.3522 14.3175 27.1797 16.185 27.1797 18.1323V26.6982H21.9434V18.1323C21.9434 17.4832 21.6675 16.8607 21.1765 16.4017C20.6855 15.9427 20.0196 15.6849 19.3252 15.6849C18.6308 15.6849 17.9649 15.9427 17.4739 16.4017C16.9829 16.8607 16.707 17.4832 16.707 18.1323V26.6982H11.4707V18.1323C11.4707 16.185 12.2982 14.3175 13.7712 12.9405C15.2442 11.5636 17.2421 10.79 19.3252 10.79Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.23633 12.0156H1V26.7001H6.23633V12.0156Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.61816 5.89483C5.06413 5.89483 6.23633 4.79908 6.23633 3.44741C6.23633 2.09574 5.06413 1 3.61816 1C2.17219 1 1 2.09574 1 3.44741C1 4.79908 2.17219 5.89483 3.61816 5.89483Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://emitus.com' + '/' + usernamep" target="_blank">
          <svg width="16" height="26" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4 1H11.4727C9.73677 1 8.0719 1.63214 6.84439 2.75736C5.61688 3.88258 4.92727 5.4087 4.92727 7V10.6H1V15.4H4.92727V25H10.1636V15.4H14.0909L15.4 10.6H10.1636V7C10.1636 6.68174 10.3016 6.37652 10.5471 6.15147C10.7926 5.92643 11.1255 5.8 11.4727 5.8H15.4V1Z" fill="white" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <a :href="'https://api.whatsapp.com/send?text=Entra%20a%20mi%20perfil%20https://emitus.com' + '/' + usernamep" target="_blank">
          <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.60081 15.4874L0.173828 20.6978L5.50588 19.2997C6.97489 20.1003 8.62888 20.5229 10.3122 20.5234H10.3166C15.8598 20.5234 20.3721 16.0126 20.3744 10.4685C20.3755 7.78188 19.3303 5.25567 17.4311 3.35515C15.5323 1.45461 13.007 0.407465 10.3162 0.40625C4.77238 0.40625 0.260356 4.91666 0.258047 10.4606C0.257492 12.2325 0.720454 13.9625 1.60081 15.4874ZM7.13739 5.64707C7.32291 5.65465 7.52821 5.66333 7.72321 6.09687C7.85646 6.39319 8.08066 6.94554 8.25936 7.3858C8.39109 7.71035 8.4981 7.97398 8.52572 8.02922C8.59081 8.15941 8.63421 8.31141 8.54742 8.48526C8.53441 8.51128 8.52237 8.53585 8.51087 8.55931C8.44567 8.69235 8.39776 8.79011 8.28733 8.91934C8.24404 8.96989 8.19928 9.02434 8.15452 9.07879C8.06467 9.18809 7.9748 9.29741 7.89679 9.37525C7.76619 9.50504 7.63059 9.64607 7.78247 9.90643C7.93435 10.1671 8.45685 11.0192 9.23075 11.7092C10.0621 12.4505 10.7844 12.764 11.1511 12.9232C11.2232 12.9545 11.2815 12.9798 11.3242 13.0012C11.5846 13.1314 11.7365 13.1097 11.8884 12.9358C12.0402 12.7622 12.5389 12.176 12.7124 11.9156C12.886 11.655 13.0596 11.6983 13.2983 11.7851C13.5369 11.8719 14.8168 12.5018 15.0771 12.632C15.1279 12.6574 15.1754 12.6803 15.2194 12.7016C15.4011 12.7892 15.5238 12.8484 15.5762 12.9358C15.6413 13.0447 15.6413 13.5656 15.4243 14.1734C15.2073 14.7814 14.1444 15.3676 13.6671 15.411C13.6212 15.4152 13.5755 15.4205 13.5286 15.4261C13.0875 15.4779 12.5307 15.5434 10.5431 14.7597C8.09766 13.7953 6.48518 11.4054 6.152 10.9116C6.12467 10.8711 6.10594 10.8434 6.09607 10.8302L6.09323 10.8264C5.95303 10.6391 5.03306 9.40973 5.03306 8.13774C5.03306 6.93888 5.62236 6.31043 5.89349 6.02129C5.91202 6.00153 5.92906 5.98335 5.94433 5.96669C6.18286 5.70619 6.46492 5.64096 6.6385 5.64096C6.64798 5.64096 6.65747 5.64095 6.66695 5.64095C6.83087 5.64093 6.99395 5.64091 7.13739 5.64707Z" fill="white"/>
          </svg>
        </a>
        <a target="_blank">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 0H6.5C2.91015 0 0 2.91015 0 6.5V19.5C0 23.0898 2.91015 26 6.5 26H19.5C23.0898 26 26 23.0898 26 19.5V6.5C26 2.91015 23.0898 0 19.5 0ZM13.1171 10.26C12.4236 10.1572 11.7153 10.2756 11.0929 10.5985C10.4706 10.9215 9.96594 11.4324 9.65072 12.0586C9.33551 12.6849 9.22579 13.3946 9.33718 14.0868C9.44856 14.7791 9.77538 15.4185 10.2712 15.9143C10.7669 16.4101 11.4064 16.7369 12.0986 16.8483C12.7908 16.9597 13.5005 16.8499 14.1268 16.5347C14.7531 16.2195 15.264 15.7148 15.5869 15.0925C15.9098 14.4702 16.0283 13.7619 15.9255 13.0683C15.8205 12.3609 15.4909 11.706 14.9852 11.2003C14.4795 10.6946 13.8246 10.3649 13.1171 10.26ZM9.37348 7.28475C10.6928 6.60016 12.1944 6.34902 13.6647 6.56705C15.1645 6.78945 16.553 7.4883 17.6251 8.56039C18.6972 9.63248 19.396 11.021 19.6184 12.5207C19.8364 13.991 19.5853 15.4926 18.9007 16.812C18.2161 18.1313 17.133 19.2012 15.8053 19.8695C14.4776 20.5377 12.973 20.7703 11.5055 20.5342C10.038 20.2981 8.68231 19.6052 7.63129 18.5542C6.58026 17.5031 5.8874 16.1475 5.65126 14.68C5.41512 13.2125 5.64772 11.7079 6.31598 10.3802C6.98424 9.05249 8.05413 7.96933 9.37348 7.28475ZM20.22 7.93915C21.1303 7.93915 21.8683 7.20122 21.8683 6.29094C21.8683 5.38065 21.1303 4.64272 20.22 4.64272C19.3098 4.64272 18.5718 5.38065 18.5718 6.29094C18.5718 7.20122 19.3098 7.93915 20.22 7.93915Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    usernamep: String,
    titlep: String
  },
  data () {
    return {
      linkHost: null
    }
  },
  created () {
    this.linkHost = window.location.host
  },
  methods: {
    hiddeShareModal () {
      this.notify.$emit('hiddeShareSocial')
    }
  }
}
</script>

<style scoped>
.modal-share-profile{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(13, 18, 57, 0.87);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-modal-share-profile {
  gap: 20px;
  position: absolute;
  margin: auto;
  background: #fff;
  z-index: 6;
  display: flex;
  width: 80%;
  max-width: 472px;
  height: 300px;
  padding: 0 15px 0 15px;
  border-radius: 6px;
}
.container-modal-share-profile a {
  background: linear-gradient(311.42deg, #00D6CB 0%, #00D6CB 94.49%);
  border-radius: 7.55556px;
  width: 69px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-modal-share-profile a svg {
  
}
.title-share-profile-modal {
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #0D1239;
  margin-bottom: 30px;
}
.bg-modal-share-profile {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(13, 18, 57, 0.87);
  z-index: 5;
}
.cancel-modal-share-profile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
@media (min-width: 1024px) {
  .title-share-profile-modal {
    font-size: 20px;
  }
}

</style>
